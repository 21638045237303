import { uuid } from "uuidv4";

export const durationOptionList = [
  {
    label: "15 min",
    value: 15,
  },
  {
    label: "30 min",
    value: 30,
  },
  {
    label: "1 hr",
    value: 1,
  },
];

export const defaultDuration = ["1", "15", "30"];

export const communicationTypeOptionList = [
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "In-Person",
    value: "in-person",
  },
];

export const defaultCommunicationType = ["phone", "video", "in-person"];

export const defaultTimeZone = "America/Detroit";

export const defaultAvailabilityWindow = [
  {
    id: uuid(),
    selecteDay: "0",
    startTime: "9:00",
    endTime: "17:00",
  },
  {
    id: uuid(),
    selecteDay: "1",
    startTime: "9:00",
    endTime: "17:00",
  },
  {
    id: uuid(),
    selecteDay: "2",
    startTime: "9:00",
    endTime: "17:00",
  },
  {
    id: uuid(),
    selecteDay: "3",
    startTime: "9:00",
    endTime: "17:00",
  },
  {
    id: uuid(),
    selecteDay: "4",
    startTime: "9:00",
    endTime: "17:00",
  },
  {
    id: uuid(),
    selecteDay: "5",
    startTime: "9:00",
    endTime: "17:00",
  },
  {
    id: uuid(),
    selecteDay: "6",
    startTime: "9:00",
    endTime: "17:00",
  },
];

export const minimumNoticeTimeOptions = [
  { label: "15 minutes", value: "15_minutes" },
  { label: "30 minutes", value: "30_minutes" },
  { label: "1 hour", value: "1_hours" },
  { label: "2 hour", value: "2_hours" },
  { label: "3 hour", value: "3_hours" },
  { label: "4 hour", value: "4_hours" },
  { label: "5 hour", value: "5_hours" },
  { label: "6 hour", value: "6_hours" },
  { label: "1 day", value: "1_days" },
  { label: "3 day", value: "3_days" },
  { label: "7 day", value: "7_days" },
];

export const usTimeZone = [
  { value: "US/Alaska", label: "US/Alaska" },
  { value: "US/Aleutian", label: "US/Aleutian" },
  { value: "US/Arizona", label: "US/Arizona" },
  { value: "US/Central", label: "US/Central" },
  { value: "US/East-Indiana", label: "US/East-Indiana" },
  { value: "US/Eastern", label: "US/Eastern" },
  { value: "US/Hawaii", label: "US/Hawaii" },
  { value: "US/Indiana-Starke", label: "US/Indiana-Starke" },
  { value: "US/Michigan", label: "US/Michigan" },
  { value: "US/Mountain", label: "US/Mountain" },
  { value: "US/Pacific", label: "US/Pacific" },
  { value: "US/Pacific-New", label: "US/Pacific-New" },
  { value: "US/Samoa", label: "US/Samoa" },
];

export const usTimeZoneWithLocale = [
  { value: "US/Alaska", label: "US/Alaska", locale: "en-US" },
  { value: "US/Aleutian", label: "US/Aleutian", locale: "en-US" },
  { value: "US/Arizona", label: "US/Arizona", locale: "en-US" },
  { value: "US/Central", label: "US/Central", locale: "en-US" },
  { value: "US/East-Indiana", label: "US/East-Indiana", locale: "en-US" },
  { value: "US/Eastern", label: "US/Eastern", locale: "en-US" },
  { value: "US/Hawaii", label: "US/Hawaii", locale: "en-US" },
  { value: "US/Indiana-Starke", label: "US/Indiana-Starke", locale: "en-US" },
  { value: "US/Michigan", label: "US/Michigan", locale: "en-US" },
  { value: "US/Mountain", label: "US/Mountain", locale: "en-US" },
  { value: "US/Pacific", label: "US/Pacific", locale: "en-US" },
  { value: "US/Pacific-New", label: "US/Pacific-New", locale: "en-US" },
  { value: "US/Samoa", label: "US/Samoa", locale: "en-US" },
];

export const europeTimeZone = [{
  label: "Europe/Amsterdam",
  value: "Europe/Amsterdam"
}, {
  label: "Europe/Andorra",
  value: "Europe/Andorra"
}, {
  label: "Europe/Astrakhan",
  value: "Europe/Astrakhan"
}, {
  label: "Europe/Athens",
  value: "Europe/Athens"
}, {
  label: "Europe/Belfast",
  value: "Europe/Belfast"
}, {
  label: "Europe/Belgrade",
  value: "Europe/Belgrade"
}, {
  label: "Europe/Berlin",
  value: "Europe/Berlin"
}, {
  label: "Europe/Bratislava",
  value: "Europe/Bratislava"
}, {
  label: "Europe/Brussels",
  value: "Europe/Brussels"
}, {
  label: "Europe/Bucharest",
  value: "Europe/Bucharest"
}, {
  label: "Europe/Budapest",
  value: "Europe/Budapest"
}, {
  label: "Europe/Busingen",
  value: "Europe/Busingen"
}, {
  label: "Europe/Chisinau",
  value: "Europe/Chisinau"
}, {
  label: "Europe/Copenhagen",
  value: "Europe/Copenhagen"
}, {
  label: "Europe/Dublin",
  value: "Europe/Dublin"
}, {
  label: "Europe/Gibraltar",
  value: "Europe/Gibraltar"
}, {
  label: "Europe/Guernsey",
  value: "Europe/Guernsey"
}, {
  label: "Europe/Helsinki",
  value: "Europe/Helsinki"
}, {
  label: "Europe/Isle_of_Man",
  value: "Europe/Isle_of_Man"
}, {
  label: "Europe/Istanbul",
  value: "Europe/Istanbul"
}, {
  label: "Europe/Jersey",
  value: "Europe/Jersey"
}, {
  label: "Europe/Kaliningrad",
  value: "Europe/Kaliningrad"
}, {
  label: "Europe/Kiev",
  value: "Europe/Kiev"
}, {
  label: "Europe/Kirov",
  value: "Europe/Kirov"
}, {
  label: "Europe/Lisbon",
  value: "Europe/Lisbon"
}, {
  label: "Europe/Ljubljana",
  value: "Europe/Ljubljana"
}, {
  label: "Europe/London",
  value: "Europe/London"
}, {
  label: "Europe/Luxembourg",
  value: "Europe/Luxembourg"
}, {
  label: "Europe/Madrid",
  value: "Europe/Madrid"
}, {
  label: "Europe/Malta",
  value: "Europe/Malta"
}, {
  label: "Europe/Mariehamn",
  value: "Europe/Mariehamn"
}, {
  label: "Europe/Minsk",
  value: "Europe/Minsk"
}, {
  label: "Europe/Monaco",
  value: "Europe/Monaco"
}, {
  label: "Europe/Moscow",
  value: "Europe/Moscow"
}, {
  label: "Europe/Nicosia",
  value: "Europe/Nicosia"
}, {
  label: "Europe/Oslo",
  value: "Europe/Oslo"
}, {
  label: "Europe/Paris",
  value: "Europe/Paris"
}, {
  label: "Europe/Podgorica",
  value: "Europe/Podgorica"
}, {
  label: "Europe/Prague",
  value: "Europe/Prague"
}, {
  label: "Europe/Riga",
  value: "Europe/Riga"
}, {
  label: "Europe/Rome",
  value: "Europe/Rome"
}, {
  label: "Europe/Samara",
  value: "Europe/Samara"
}, {
  label: "Europe/San_Marino",
  value: "Europe/San_Marino"
}, {
  label: "Europe/Sarajevo",
  value: "Europe/Sarajevo"
}, {
  label: "Europe/Saratov",
  value: "Europe/Saratov"
}, {
  label: "Europe/Simferopol",
  value: "Europe/Simferopol"
}, {
  label: "Europe/Skopje",
  value: "Europe/Skopje"
}, {
  label: "Europe/Sofia",
  value: "Europe/Sofia"
}, {
  label: "Europe/Stockholm",
  value: "Europe/Stockholm"
}, {
  label: "Europe/Tallinn",
  value: "Europe/Tallinn"
}, {
  label: "Europe/Tirane",
  value: "Europe/Tirane"
}, {
  label: "Europe/Tiraspol",
  value: "Europe/Tiraspol"
}, {
  label: "Europe/Ulyanovsk",
  value: "Europe/Ulyanovsk"
}, {
  label: "Europe/Uzhgorod",
  value: "Europe/Uzhgorod"
}, {
  label: "Europe/Vaduz",
  value: "Europe/Vaduz"
}, {
  label: "Europe/Vatican",
  value: "Europe/Vatican"
}, {
  label: "Europe/Vienna",
  value: "Europe/Vienna"
}, {
  label: "Europe/Vilnius",
  value: "Europe/Vilnius"
}, {
  label: "Europe/Volgograd",
  value: "Europe/Volgograd"
}, {
  label: "Europe/Warsaw",
  value: "Europe/Warsaw"
}, {
  label: "Europe/Zagreb",
  value: "Europe/Zagreb"
}, {
  label: "Europe/Zaporozhye",
  value: "Europe/Zaporozhye"
}, {
  label: "Europe/Zurich",
  value: "Europe/Zurich"
}];

export const europeTimeZoneWithLocale = [
  { label: "Europe/Amsterdam", value: "Europe/Amsterdam", locale: "nl-NL" },
  { label: "Europe/Andorra", value: "Europe/Andorra", locale: "ca-AD" },
  { label: "Europe/Astrakhan", value: "Europe/Astrakhan", locale: "ru-RU" },
  { label: "Europe/Athens", value: "Europe/Athens", locale: "el-GR" },
  { label: "Europe/Belfast", value: "Europe/Belfast", locale: "en-GB" },
  { label: "Europe/Belgrade", value: "Europe/Belgrade", locale: "sr-RS" },
  { label: "Europe/Berlin", value: "Europe/Berlin", locale: "de-DE" },
  { label: "Europe/Bratislava", value: "Europe/Bratislava", locale: "sk-SK" },
  { label: "Europe/Brussels", value: "Europe/Brussels", locale: "nl-BE" },
  { label: "Europe/Bucharest", value: "Europe/Bucharest", locale: "ro-RO" },
  { label: "Europe/Budapest", value: "Europe/Budapest", locale: "hu-HU" },
  { label: "Europe/Busingen", value: "Europe/Busingen", locale: "de-DE" },
  { label: "Europe/Chisinau", value: "Europe/Chisinau", locale: "ro-MD" },
  { label: "Europe/Copenhagen", value: "Europe/Copenhagen", locale: "da-DK" },
  { label: "Europe/Dublin", value: "Europe/Dublin", locale: "en-IE" },
  { label: "Europe/Gibraltar", value: "Europe/Gibraltar", locale: "en-GB" },
  { label: "Europe/Guernsey", value: "Europe/Guernsey", locale: "en-GB" },
  { label: "Europe/Helsinki", value: "Europe/Helsinki", locale: "fi-FI" },
  { label: "Europe/Isle_of_Man", value: "Europe/Isle_of_Man", locale: "en-GB" },
  { label: "Europe/Istanbul", value: "Europe/Istanbul", locale: "tr-TR" },
  { label: "Europe/Jersey", value: "Europe/Jersey", locale: "en-GB" },
  { label: "Europe/Kaliningrad", value: "Europe/Kaliningrad", locale: "ru-RU" },
  { label: "Europe/Kiev", value: "Europe/Kiev", locale: "uk-UA" },
  { label: "Europe/Kirov", value: "Europe/Kirov", locale: "ru-RU" },
  { label: "Europe/Lisbon", value: "Europe/Lisbon", locale: "pt-PT" },
  { label: "Europe/Ljubljana", value: "Europe/Ljubljana", locale: "sl-SI" },
  { label: "Europe/London", value: "Europe/London", locale: "en-GB" },
  { label: "Europe/Luxembourg", value: "Europe/Luxembourg", locale: "lb-LU" },
  { label: "Europe/Madrid", value: "Europe/Madrid", locale: "es-ES" },
  { label: "Europe/Malta", value: "Europe/Malta", locale: "mt-MT" },
  { label: "Europe/Mariehamn", value: "Europe/Mariehamn", locale: "sv-FI" },
  { label: "Europe/Minsk", value: "Europe/Minsk", locale: "be-BY" },
  { label: "Europe/Monaco", value: "Europe/Monaco", locale: "fr-MC" },
  { label: "Europe/Moscow", value: "Europe/Moscow", locale: "ru-RU" },
  { label: "Europe/Nicosia", value: "Europe/Nicosia", locale: "el-CY" },
  { label: "Europe/Oslo", value: "Europe/Oslo", locale: "no-NO" },
  { label: "Europe/Paris", value: "Europe/Paris", locale: "fr-FR" },
  { label: "Europe/Podgorica", value: "Europe/Podgorica", locale: "sr-ME" },
  { label: "Europe/Prague", value: "Europe/Prague", locale: "cs-CZ" },
  { label: "Europe/Riga", value: "Europe/Riga", locale: "lv-LV" },
  { label: "Europe/Rome", value: "Europe/Rome", locale: "it-IT" },
  { label: "Europe/Samara", value: "Europe/Samara", locale: "ru-RU" },
  { label: "Europe/San_Marino", value: "Europe/San_Marino", locale: "it-SM" },
  { label: "Europe/Sarajevo", value: "Europe/Sarajevo", locale: "bs-BA" },
  { label: "Europe/Saratov", value: "Europe/Saratov", locale: "ru-RU" },
  { label: "Europe/Simferopol", value: "Europe/Simferopol", locale: "uk-UA" },
  { label: "Europe/Skopje", value: "Europe/Skopje", locale: "mk-MK" },
  { label: "Europe/Sofia", value: "Europe/Sofia", locale: "bg-BG" },
  { label: "Europe/Stockholm", value: "Europe/Stockholm", locale: "sv-SE" },
  { label: "Europe/Tallinn", value: "Europe/Tallinn", locale: "et-EE" },
  { label: "Europe/Tirane", value: "Europe/Tirane", locale: "sq-AL" },
  { label: "Europe/Tiraspol", value: "Europe/Tiraspol", locale: "ru-RU" },
  { label: "Europe/Ulyanovsk", value: "Europe/Ulyanovsk", locale: "ru-RU" },
  { label: "Europe/Uzhgorod", value: "Europe/Uzhgorod", locale: "uk-UA" },
  { label: "Europe/Vaduz", value: "Europe/Vaduz", locale: "de-LI" },
  { label: "Europe/Vatican", value: "Europe/Vatican", locale: "it-VA" },
  { label: "Europe/Vienna", value: "Europe/Vienna", locale: "de-AT" },
  { label: "Europe/Vilnius", value: "Europe/Vilnius", locale: "lt-LT" },
  { label: "Europe/Volgograd", value: "Europe/Volgograd", locale: "ru-RU" },
  { label: "Europe/Warsaw", value: "Europe/Warsaw", locale: "pl-PL" },
  { label: "Europe/Zagreb", value: "Europe/Zagreb", locale: "hr-HR" },
  { label: "Europe/Zaporozhye", value: "Europe/Zaporozhye", locale: "uk-UA" },
  { label: "Europe/Zurich", value: "Europe/Zurich", locale: "de-CH" },
];

export const canadaTimeZone = [{
  label: "Canada/Atlantic",
  value: "Canada/Atlantic"
}, {
  label: "Canada/Central",
  value: "Canada/Central"
}, {
  label: "Canada/Eastern",
  value: "Canada/Eastern"
}, {
  label: "Canada/Mountain",
  value: "Canada/Mountain"
}, {
  label: "Canada/Newfoundland",
  value: "Canada/Newfoundland"
}, {
  label: "Canada/Pacific",
  value: "Canada/Pacific"
}, {
  label: "Canada/Saskatchewan",
  value: "Canada/Saskatchewan"
}, {
  label: "Canada/Yukon",
  value: "Canada/Yukon"
}];

export const canadaTimeZoneWithLocale  = [
  { label: "Canada/Atlantic", value: "Canada/Atlantic", locale: "en-CA" },
  { label: "Canada/Central", value: "Canada/Central", locale: "en-CA" },
  { label: "Canada/Eastern", value: "Canada/Eastern", locale: "en-CA" },
  { label: "Canada/Mountain", value: "Canada/Mountain", locale: "en-CA" },
  {
    label: "Canada/Newfoundland",
    value: "Canada/Newfoundland",
    locale: "en-CA",
  },
  { label: "Canada/Pacific", value: "Canada/Pacific", locale: "en-CA" },
  {
    label: "Canada/Saskatchewan",
    value: "Canada/Saskatchewan",
    locale: "en-CA",
  },
  { label: "Canada/Yukon", value: "Canada/Yukon", locale: "en-CA" },
];

export const africaTimeZone = [{
  label: "Africa/Abidjan",
  value: "Africa/Abidjan"
}, {
  label: "Africa/Accra",
  value: "Africa/Accra"
}, {
  label: "Africa/Algiers",
  value: "Africa/Algiers"
}, {
  label: "Africa/Asmara",
  value: "Africa/Asmara"
}, {
  label: "Africa/Asmera",
  value: "Africa/Asmera"
}, {
  label: "Africa/Bamako",
  value: "Africa/Bamako"
}, {
  label: "Africa/Bangui",
  value: "Africa/Bangui"
}, {
  label: "Africa/Banjul",
  value: "Africa/Banjul"
}, {
  label: "Africa/Bissau",
  value: "Africa/Bissau"
}, {
  label: "Africa/Blantyre",
  value: "Africa/Blantyre"
}, {
  label: "Africa/Brazzaville",
  value: "Africa/Brazzaville"
}, {
  label: "Africa/Bujumbura",
  value: "Africa/Bujumbura"
}, {
  label: "Africa/Cairo",
  value: "Africa/Cairo"
}, {
  label: "Africa/Casablanca",
  value: "Africa/Casablanca"
}, {
  label: "Africa/Ceuta",
  value: "Africa/Ceuta"
}, {
  label: "Africa/Addis_Ababa",
  value: "Africa/Addis_Ababa"
}, {
  label: "Africa/Conakry",
  value: "Africa/Conakry"
}, {
  label: "Africa/Dakar",
  value: "Africa/Dakar"
}, {
  label: "Africa/Dar_es_Salaam",
  value: "Africa/Dar_es_Salaam"
}, {
  label: "Africa/Djibouti",
  value: "Africa/Djibouti"
}, {
  label: "Africa/Douala",
  value: "Africa/Douala"
}, {
  label: "Africa/El_Aaiun",
  value: "Africa/El_Aaiun"
}, {
  label: "Africa/Freetown",
  value: "Africa/Freetown"
}, {
  label: "Africa/Gaborone",
  value: "Africa/Gaborone"
}, {
  label: "Africa/Harare",
  value: "Africa/Harare"
}, {
  label: "Africa/Johannesburg",
  value: "Africa/Johannesburg"
}, {
  label: "Africa/Juba",
  value: "Africa/Juba"
}, {
  label: "Africa/Kampala",
  value: "Africa/Kampala"
}, {
  label: "Africa/Khartoum",
  value: "Africa/Khartoum"
}, {
  label: "Africa/Kigali",
  value: "Africa/Kigali"
}, {
  label: "Africa/Kinshasa",
  value: "Africa/Kinshasa"
}, {
  label: "Africa/Lagos",
  value: "Africa/Lagos"
}, {
  label: "Africa/Libreville",
  value: "Africa/Libreville"
}, {
  label: "Africa/Lome",
  value: "Africa/Lome"
}, {
  label: "Africa/Luanda",
  value: "Africa/Luanda"
}, {
  label: "Africa/Lubumbashi",
  value: "Africa/Lubumbashi"
}, {
  label: "Africa/Lusaka",
  value: "Africa/Lusaka"
}, {
  label: "Africa/Malabo",
  value: "Africa/Malabo"
}, {
  label: "Africa/Maputo",
  value: "Africa/Maputo"
}, {
  label: "Africa/Maseru",
  value: "Africa/Maseru"
}, {
  label: "Africa/Mbabane",
  value: "Africa/Mbabane"
}, {
  label: "Africa/Mogadishu",
  value: "Africa/Mogadishu"
}, {
  label: "Africa/Monrovia",
  value: "Africa/Monrovia"
}, {
  label: "Africa/Nairobi",
  value: "Africa/Nairobi"
}, {
  label: "Africa/Ndjamena",
  value: "Africa/Ndjamena"
}, {
  label: "Africa/Niamey",
  value: "Africa/Niamey"
}, {
  label: "Africa/Nouakchott",
  value: "Africa/Nouakchott"
}, {
  label: "Africa/Ouagadougou",
  value: "Africa/Ouagadougou"
}, {
  label: "Africa/Porto-Novo",
  value: "Africa/Porto-Novo"
}, {
  label: "Africa/Sao_Tome",
  value: "Africa/Sao_Tome"
}, {
  label: "Africa/Timbuktu",
  value: "Africa/Timbuktu"
}, {
  label: "Africa/Tripoli",
  value: "Africa/Tripoli"
}, {
  label: "Africa/Tunis",
  value: "Africa/Tunis"
}, {
  label: "Africa/Windhoek",
  value: "Africa/Windhoek"
}];

export const africaTimeZoneWithLocale = [
  { label: "Africa/Abidjan", value: "Africa/Abidjan", locale: "fr-CI" },
  { label: "Africa/Accra", value: "Africa/Accra", locale: "en-GH" },
  { label: "Africa/Algiers", value: "Africa/Algiers", locale: "fr-DZ" },
  { label: "Africa/Asmara", value: "Africa/Asmara", locale: "it-ER" },
  { label: "Africa/Asmera", value: "Africa/Asmera", locale: "it-ER" },
  { label: "Africa/Bamako", value: "Africa/Bamako", locale: "fr-ML" },
  { label: "Africa/Bangui", value: "Africa/Bangui", locale: "fr-CF" },
  { label: "Africa/Banjul", value: "Africa/Banjul", locale: "en-GM" },
  { label: "Africa/Bissau", value: "Africa/Bissau", locale: "pt-GW" },
  { label: "Africa/Blantyre", value: "Africa/Blantyre", locale: "en-MW" },
  { label: "Africa/Brazzaville", value: "Africa/Brazzaville", locale: "fr-CG" },
  { label: "Africa/Bujumbura", value: "Africa/Bujumbura", locale: "fr-BI" },
  { label: "Africa/Cairo", value: "Africa/Cairo", locale: "ar-EG" },
  { label: "Africa/Casablanca", value: "Africa/Casablanca", locale: "fr-MA" },
  { label: "Africa/Ceuta", value: "Africa/Ceuta", locale: "es-ES" },
  { label: "Africa/Addis_Ababa", value: "Africa/Addis_Ababa", locale: "am-ET" },
  { label: "Africa/Conakry", value: "Africa/Conakry", locale: "fr-GN" },
  { label: "Africa/Dakar", value: "Africa/Dakar", locale: "fr-SN" },
  {
    label: "Africa/Dar_es_Salaam",
    value: "Africa/Dar_es_Salaam",
    locale: "sw-TZ",
  },
  { label: "Africa/Djibouti", value: "Africa/Djibouti", locale: "fr-DJ" },
  { label: "Africa/Douala", value: "Africa/Douala", locale: "fr-CM" },
  { label: "Africa/El_Aaiun", value: "Africa/El_Aaiun", locale: "es-ES" },
  { label: "Africa/Freetown", value: "Africa/Freetown", locale: "en-SL" },
  { label: "Africa/Gaborone", value: "Africa/Gaborone", locale: "en-BW" },
  { label: "Africa/Harare", value: "Africa/Harare", locale: "en-ZW" },
  {
    label: "Africa/Johannesburg",
    value: "Africa/Johannesburg",
    locale: "en-ZA",
  },
  { label: "Africa/Juba", value: "Africa/Juba", locale: "en-SS" },
  { label: "Africa/Kampala", value: "Africa/Kampala", locale: "en-UG" },
  { label: "Africa/Khartoum", value: "Africa/Khartoum", locale: "ar-SD" },
  { label: "Africa/Kigali", value: "Africa/Kigali", locale: "rw-RW" },
  { label: "Africa/Kinshasa", value: "Africa/Kinshasa", locale: "fr-CD" },
  { label: "Africa/Lagos", value: "Africa/Lagos", locale: "en-NG" },
  { label: "Africa/Libreville", value: "Africa/Libreville", locale: "fr-GA" },
  { label: "Africa/Lome", value: "Africa/Lome", locale: "fr-TG" },
  { label: "Africa/Luanda", value: "Africa/Luanda", locale: "pt-AO" },
  { label: "Africa/Lubumbashi", value: "Africa/Lubumbashi", locale: "fr-CD" },
  { label: "Africa/Lusaka", value: "Africa/Lusaka", locale: "en-ZM" },
  { label: "Africa/Malabo", value: "Africa/Malabo", locale: "es-GQ" },
  { label: "Africa/Maputo", value: "Africa/Maputo", locale: "pt-MZ" },
  { label: "Africa/Maseru", value: "Africa/Maseru", locale: "en-LS" },
  { label: "Africa/Mbabane", value: "Africa/Mbabane", locale: "en-SZ" },
  { label: "Africa/Mogadishu", value: "Africa/Mogadishu", locale: "so-SO" },
  { label: "Africa/Monrovia", value: "Africa/Monrovia", locale: "en-LR" },
  { label: "Africa/Nairobi", value: "Africa/Nairobi", locale: "sw-KE" },
  { label: "Africa/Ndjamena", value: "Africa/Ndjamena", locale: "fr-TD" },
  { label: "Africa/Niamey", value: "Africa/Niamey", locale: "fr-NE" },
  { label: "Africa/Nouakchott", value: "Africa/Nouakchott", locale: "fr-MR" },
  { label: "Africa/Ouagadougou", value: "Africa/Ouagadougou", locale: "fr-BF" },
  { label: "Africa/Porto-Novo", value: "Africa/Porto-Novo", locale: "fr-BJ" },
  { label: "Africa/Sao_Tome", value: "Africa/Sao_Tome", locale: "pt-ST" },
  { label: "Africa/Timbuktu", value: "Africa/Timbuktu", locale: "fr-ML" },
  { label: "Africa/Tripoli", value: "Africa/Tripoli", locale: "ar-LY" },
  { label: "Africa/Tunis", value: "Africa/Tunis", locale: "ar-TN" },
  { label: "Africa/Windhoek", value: "Africa/Windhoek", locale: "en-NA" },
];

export const americaTimeZone = [{
  label: "America/Adak",
  value: "America/Adak"
}, {
  label: "America/Anchorage",
  value: "America/Anchorage"
}, {
  label: "America/Anguilla",
  value: "America/Anguilla"
}, {
  label: "America/Antigua",
  value: "America/Antigua"
}, {
  label: "America/Araguaina",
  value: "America/Araguaina"
}, {
  label: "America/Argentina/Buenos_Aires",
  value: "America/Argentina/Buenos_Aires"
}, {
  label: "America/Argentina/Catamarca",
  value: "America/Argentina/Catamarca"
}, {
  label: "America/Argentina/ComodRivadavia",
  value: "America/Argentina/ComodRivadavia"
}, {
  label: "America/Argentina/Cordoba",
  value: "America/Argentina/Cordoba"
}, {
  label: "America/Argentina/Jujuy",
  value: "America/Argentina/Jujuy"
}, {
  label: "America/Argentina/La_Rioja",
  value: "America/Argentina/La_Rioja"
}, {
  label: "America/Argentina/Mendoza",
  value: "America/Argentina/Mendoza"
}, {
  label: "America/Argentina/Rio_Gallegos",
  value: "America/Argentina/Rio_Gallegos"
}, {
  label: "America/Argentina/Salta",
  value: "America/Argentina/Salta"
}, {
  label: "America/Argentina/San_Juan",
  value: "America/Argentina/San_Juan"
}, {
  label: "America/Argentina/San_Luis",
  value: "America/Argentina/San_Luis"
}, {
  label: "America/Argentina/Tucuman",
  value: "America/Argentina/Tucuman"
}, {
  label: "America/Argentina/Ushuaia",
  value: "America/Argentina/Ushuaia"
}, {
  label: "America/Aruba",
  value: "America/Aruba"
}, {
  label: "America/Asuncion",
  value: "America/Asuncion"
}, {
  label: "America/Atikokan",
  value: "America/Atikokan"
}, {
  label: "America/Atka",
  value: "America/Atka"
}, {
  label: "America/Bahia",
  value: "America/Bahia"
}, {
  label: "America/Bahia_Banderas",
  value: "America/Bahia_Banderas"
}, {
  label: "America/Barbados",
  value: "America/Barbados"
}, {
  label: "America/Belem",
  value: "America/Belem"
}, {
  label: "America/Belize",
  value: "America/Belize"
}, {
  label: "America/Blanc-Sablon",
  value: "America/Blanc-Sablon"
}, {
  label: "America/Boa_Vista",
  value: "America/Boa_Vista"
}, {
  label: "America/Bogota",
  value: "America/Bogota"
}, {
  label: "America/Boise",
  value: "America/Boise"
}, {
  label: "America/Buenos_Aires",
  value: "America/Buenos_Aires"
}, {
  label: "America/Cambridge_Bay",
  value: "America/Cambridge_Bay"
}, {
  label: "America/Campo_Grande",
  value: "America/Campo_Grande"
}, {
  label: "America/Cancun",
  value: "America/Cancun"
}, {
  label: "America/Caracas",
  value: "America/Caracas"
}, {
  label: "America/Catamarca",
  value: "America/Catamarca"
}, {
  label: "America/Cayenne",
  value: "America/Cayenne"
}, {
  label: "America/Cayman",
  value: "America/Cayman"
}, {
  label: "America/Chicago",
  value: "America/Chicago"
}, {
  label: "America/Chihuahua",
  value: "America/Chihuahua"
}, {
  label: "America/Coral_Harbour",
  value: "America/Coral_Harbour"
}, {
  label: "America/Cordoba",
  value: "America/Cordoba"
}, {
  label: "America/Costa_Rica",
  value: "America/Costa_Rica"
}, {
  label: "America/Creston",
  value: "America/Creston"
}, {
  label: "America/Cuiaba",
  value: "America/Cuiaba"
}, {
  label: "America/Curacao",
  value: "America/Curacao"
}, {
  label: "America/Danmarkshavn",
  value: "America/Danmarkshavn"
}, {
  label: "America/Dawson",
  value: "America/Dawson"
}, {
  label: "America/Dawson_Creek",
  value: "America/Dawson_Creek"
}, {
  label: "America/Denver",
  value: "America/Denver"
}, {
  label: "America/Detroit",
  value: "America/Detroit"
}, {
  label: "America/Dominica",
  value: "America/Dominica"
}, {
  label: "America/Edmonton",
  value: "America/Edmonton"
}, {
  label: "America/Eirunepe",
  value: "America/Eirunepe"
}, {
  label: "America/El_Salvador",
  value: "America/El_Salvador"
}, {
  label: "America/Ensenada",
  value: "America/Ensenada"
}, {
  label: "America/Fort_Nelson",
  value: "America/Fort_Nelson"
}, {
  label: "America/Fort_Wayne",
  value: "America/Fort_Wayne"
}, {
  label: "America/Fortaleza",
  value: "America/Fortaleza"
}, {
  label: "America/Glace_Bay",
  value: "America/Glace_Bay"
}, {
  label: "America/Godthab",
  value: "America/Godthab"
}, {
  label: "America/Goose_Bay",
  value: "America/Goose_Bay"
}, {
  label: "America/Grand_Turk",
  value: "America/Grand_Turk"
}, {
  label: "America/Grenada",
  value: "America/Grenada"
}, {
  label: "America/Guadeloupe",
  value: "America/Guadeloupe"
}, {
  label: "America/Guatemala",
  value: "America/Guatemala"
}, {
  label: "America/Guayaquil",
  value: "America/Guayaquil"
}, {
  label: "America/Guyana",
  value: "America/Guyana"
}, {
  label: "America/Halifax",
  value: "America/Halifax"
}, {
  label: "America/Havana",
  value: "America/Havana"
}, {
  label: "America/Hermosillo",
  value: "America/Hermosillo"
}, {
  label: "America/Indiana/Indianapolis",
  value: "America/Indiana/Indianapolis"
}, {
  label: "America/Indiana/Knox",
  value: "America/Indiana/Knox"
}, {
  label: "America/Indiana/Marengo",
  value: "America/Indiana/Marengo"
}, {
  label: "America/Indiana/Petersburg",
  value: "America/Indiana/Petersburg"
}, {
  label: "America/Indiana/Tell_City",
  value: "America/Indiana/Tell_City"
}, {
  label: "America/Indiana/Vevay",
  value: "America/Indiana/Vevay"
}, {
  label: "America/Indiana/Vincennes",
  value: "America/Indiana/Vincennes"
}, {
  label: "America/Indiana/Winamac",
  value: "America/Indiana/Winamac"
}, {
  label: "America/Indianapolis",
  value: "America/Indianapolis"
}, {
  label: "America/Inuvik",
  value: "America/Inuvik"
}, {
  label: "America/Iqaluit",
  value: "America/Iqaluit"
}, {
  label: "America/Jamaica",
  value: "America/Jamaica"
}, {
  label: "America/Jujuy",
  value: "America/Jujuy"
}, {
  label: "America/Juneau",
  value: "America/Juneau"
}, {
  label: "America/Kentucky/Louisville",
  value: "America/Kentucky/Louisville"
}, {
  label: "America/Kentucky/Monticello",
  value: "America/Kentucky/Monticello"
}, {
  label: "America/Knox_IN",
  value: "America/Knox_IN"
}, {
  label: "America/Kralendijk",
  value: "America/Kralendijk"
}, {
  label: "America/La_Paz",
  value: "America/La_Paz"
}, {
  label: "America/Lima",
  value: "America/Lima"
}, {
  label: "America/Los_Angeles",
  value: "America/Los_Angeles"
}, {
  label: "America/Louisville",
  value: "America/Louisville"
}, {
  label: "America/Lower_Princes",
  value: "America/Lower_Princes"
}, {
  label: "America/Maceio",
  value: "America/Maceio"
}, {
  label: "America/Managua",
  value: "America/Managua"
}, {
  label: "America/Manaus",
  value: "America/Manaus"
}, {
  label: "America/Marigot",
  value: "America/Marigot"
}, {
  label: "America/Martinique",
  value: "America/Martinique"
}, {
  label: "America/Matamoros",
  value: "America/Matamoros"
}, {
  label: "America/Mazatlan",
  value: "America/Mazatlan"
}, {
  label: "America/Mendoza",
  value: "America/Mendoza"
}, {
  label: "America/Menominee",
  value: "America/Menominee"
}, {
  label: "America/Merida",
  value: "America/Merida"
}, {
  label: "America/Metlakatla",
  value: "America/Metlakatla"
}, {
  label: "America/Mexico_City",
  value: "America/Mexico_City"
}, {
  label: "America/Miquelon",
  value: "America/Miquelon"
}, {
  label: "America/Moncton",
  value: "America/Moncton"
}, {
  label: "America/Monterrey",
  value: "America/Monterrey"
}, {
  label: "America/Montevideo",
  value: "America/Montevideo"
}, {
  label: "America/Montreal",
  value: "America/Montreal"
}, {
  label: "America/Montserrat",
  value: "America/Montserrat"
}, {
  label: "America/Nassau",
  value: "America/Nassau"
}, {
  label: "America/New_York",
  value: "America/New_York"
}, {
  label: "America/Nipigon",
  value: "America/Nipigon"
}, {
  label: "America/Nome",
  value: "America/Nome"
}, {
  label: "America/Noronha",
  value: "America/Noronha"
}, {
  label: "America/North_Dakota/Beulah",
  value: "America/North_Dakota/Beulah"
}, {
  label: "America/North_Dakota/Center",
  value: "America/North_Dakota/Center"
}, {
  label: "America/North_Dakota/New_Salem",
  value: "America/North_Dakota/New_Salem"
}, {
  label: "America/Ojinaga",
  value: "America/Ojinaga"
}, {
  label: "America/Panama",
  value: "America/Panama"
}, {
  label: "America/Pangnirtung",
  value: "America/Pangnirtung"
}, {
  label: "America/Paramaribo",
  value: "America/Paramaribo"
}, {
  label: "America/Phoenix",
  value: "America/Phoenix"
}, {
  label: "America/Port-au-Prince",
  value: "America/Port-au-Prince"
}, {
  label: "America/Port_of_Spain",
  value: "America/Port_of_Spain"
}, {
  label: "America/Porto_Acre",
  value: "America/Porto_Acre"
}, {
  label: "America/Porto_Velho",
  value: "America/Porto_Velho"
}, {
  label: "America/Puerto_Rico",
  value: "America/Puerto_Rico"
}, {
  label: "America/Punta_Arenas",
  value: "America/Punta_Arenas"
}, {
  label: "America/Rainy_River",
  value: "America/Rainy_River"
}, {
  label: "America/Rankin_Inlet",
  value: "America/Rankin_Inlet"
}, {
  label: "America/Recife",
  value: "America/Recife"
}, {
  label: "America/Regina",
  value: "America/Regina"
}, {
  label: "America/Resolute",
  value: "America/Resolute"
}, {
  label: "America/Rio_Branco",
  value: "America/Rio_Branco"
}, {
  label: "America/Rosario",
  value: "America/Rosario"
}, {
  label: "America/Santa_Isabel",
  value: "America/Santa_Isabel"
}, {
  label: "America/Santarem",
  value: "America/Santarem"
}, {
  label: "America/Santiago",
  value: "America/Santiago"
}, {
  label: "America/Santo_Domingo",
  value: "America/Santo_Domingo"
}, {
  label: "America/Sao_Paulo",
  value: "America/Sao_Paulo"
}, {
  label: "America/Scoresbysund",
  value: "America/Scoresbysund"
}, {
  label: "America/Shiprock",
  value: "America/Shiprock"
}, {
  label: "America/Sitka",
  value: "America/Sitka"
}, {
  label: "America/St_Barthelemy",
  value: "America/St_Barthelemy"
}, {
  label: "America/St_Johns",
  value: "America/St_Johns"
}, {
  label: "America/St_Kitts",
  value: "America/St_Kitts"
}, {
  label: "America/St_Lucia",
  value: "America/St_Lucia"
}, {
  label: "America/St_Thomas",
  value: "America/St_Thomas"
}, {
  label: "America/St_Vincent",
  value: "America/St_Vincent"
}, {
  label: "America/Swift_Current",
  value: "America/Swift_Current"
}, {
  label: "America/Tegucigalpa",
  value: "America/Tegucigalpa"
}, {
  label: "America/Thule",
  value: "America/Thule"
}, {
  label: "America/Thunder_Bay",
  value: "America/Thunder_Bay"
}, {
  label: "America/Tijuana",
  value: "America/Tijuana"
}, {
  label: "America/Toronto",
  value: "America/Toronto"
}, {
  label: "America/Tortola",
  value: "America/Tortola"
}, {
  label: "America/Vancouver",
  value: "America/Vancouver"
}, {
  label: "America/Virgin",
  value: "America/Virgin"
}, {
  label: "America/Whitehorse",
  value: "America/Whitehorse"
}, {
  label: "America/Winnipeg",
  value: "America/Winnipeg"
}, {
  label: "America/Yakutat",
  value: "America/Yakutat"
}, {
  label: "America/Yellowknife",
  value: "America/Yellowknife"
}];

export const americaTimeZoneWithLocale = [
  { label: "America/Adak", value: "America/Adak", locale: "en-US" },
  { label: "America/Anchorage", value: "America/Anchorage", locale: "en-US" },
  { label: "America/Anguilla", value: "America/Anguilla", locale: "en-GB" },
  { label: "America/Antigua", value: "America/Antigua", locale: "en-AG" },
  { label: "America/Araguaina", value: "America/Araguaina", locale: "pt-BR" },
  {
    label: "America/Argentina/Buenos_Aires",
    value: "America/Argentina/Buenos_Aires",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Catamarca",
    value: "America/Argentina/Catamarca",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/ComodRivadavia",
    value: "America/Argentina/ComodRivadavia",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Cordoba",
    value: "America/Argentina/Cordoba",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Jujuy",
    value: "America/Argentina/Jujuy",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/La_Rioja",
    value: "America/Argentina/La_Rioja",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Mendoza",
    value: "America/Argentina/Mendoza",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Rio_Gallegos",
    value: "America/Argentina/Rio_Gallegos",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Salta",
    value: "America/Argentina/Salta",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/San_Juan",
    value: "America/Argentina/San_Juan",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/San_Luis",
    value: "America/Argentina/San_Luis",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Tucuman",
    value: "America/Argentina/Tucuman",
    locale: "es-AR",
  },
  {
    label: "America/Argentina/Ushuaia",
    value: "America/Argentina/Ushuaia",
    locale: "es-AR",
  },
  { label: "America/Aruba", value: "America/Aruba", locale: "en-AW" },
  { label: "America/Asuncion", value: "America/Asuncion", locale: "es-PY" },
  { label: "America/Atikokan", value: "America/Atikokan", locale: "en-CA" },
  { label: "America/Atka", value: "America/Atka", locale: "en-US" },
  { label: "America/Bahia", value: "America/Bahia", locale: "pt-BR" },
  {
    label: "America/Bahia_Banderas",
    value: "America/Bahia_Banderas",
    locale: "es-MX",
  },
  { label: "America/Barbados", value: "America/Barbados", locale: "en-BB" },
  { label: "America/Belem", value: "America/Belem", locale: "pt-BR" },
  { label: "America/Belize", value: "America/Belize", locale: "en-BZ" },
  {
    label: "America/Blanc-Sablon",
    value: "America/Blanc-Sablon",
    locale: "fr-CA",
  },
  { label: "America/Boa_Vista", value: "America/Boa_Vista", locale: "pt-BR" },
  { label: "America/Bogota", value: "America/Bogota", locale: "es-CO" },
  { label: "America/Boise", value: "America/Boise", locale: "en-US" },
  {
    label: "America/Buenos_Aires",
    value: "America/Buenos_Aires",
    locale: "es-AR",
  },
  {
    label: "America/Cambridge_Bay",
    value: "America/Cambridge_Bay",
    locale: "en-CA",
  },
  {
    label: "America/Campo_Grande",
    value: "America/Campo_Grande",
    locale: "pt-BR",
  },
  { label: "America/Cancun", value: "America/Cancun", locale: "es-MX" },
  { label: "America/Caracas", value: "America/Caracas", locale: "es-VE" },
  { label: "America/Catamarca", value: "America/Catamarca", locale: "es-AR" },
  { label: "America/Cayenne", value: "America/Cayenne", locale: "fr-FR" },
  { label: "America/Cayman", value: "America/Cayman", locale: "en-KY" },
  { label: "America/Chicago", value: "America/Chicago", locale: "en-US" },
  { label: "America/Chihuahua", value: "America/Chihuahua", locale: "es-MX" },
  {
    label: "America/Coral_Harbour",
    value: "America/Coral_Harbour",
    locale: "en-CA",
  },
  { label: "America/Cordoba", value: "America/Cordoba", locale: "es-AR" },
  { label: "America/Costa_Rica", value: "America/Costa_Rica", locale: "es-CR" },
  { label: "America/Creston", value: "America/Creston", locale: "en-CA" },
  { label: "America/Cuiaba", value: "America/Cuiaba", locale: "pt-BR" },
  { label: "America/Curacao", value: "America/Curacao", locale: "nl-CW" },
  {
    label: "America/Danmarkshavn",
    value: "America/Danmarkshavn",
    locale: "da-DK",
  },
  { label: "America/Dawson", value: "America/Dawson", locale: "en-CA" },
  {
    label: "America/Dawson_Creek",
    value: "America/Dawson_Creek",
    locale: "en-CA",
  },
  { label: "America/Denver", value: "America/Denver", locale: "en-US" },
  { label: "America/Detroit", value: "America/Detroit", locale: "en-US" },
  { label: "America/Dominica", value: "America/Dominica", locale: "en-DM" },
  { label: "America/Edmonton", value: "America/Edmonton", locale: "en-CA" },
  { label: "America/Eirunepe", value: "America/Eirunepe", locale: "pt-BR" },
  {
    label: "America/El_Salvador",
    value: "America/El_Salvador",
    locale: "es-SV",
  },
  { label: "America/Ensenada", value: "America/Ensenada", locale: "es-MX" },
  {
    label: "America/Fort_Nelson",
    value: "America/Fort_Nelson",
    locale: "en-CA",
  },
  { label: "America/Fort_Wayne", value: "America/Fort_Wayne", locale: "en-US" },
  { label: "America/Fortaleza", value: "America/Fortaleza", locale: "pt-BR" },
  { label: "America/Glace_Bay", value: "America/Glace_Bay", locale: "en-CA" },
  { label: "America/Godthab", value: "America/Godthab", locale: "da-GL" },
  { label: "America/Goose_Bay", value: "America/Goose_Bay", locale: "en-CA" },
  { label: "America/Grand_Turk", value: "America/Grand_Turk", locale: "en-TC" },
  { label: "America/Grenada", value: "America/Grenada", locale: "en-GD" },
  { label: "America/Guadeloupe", value: "America/Guadeloupe", locale: "fr-GP" },
  { label: "America/Guatemala", value: "America/Guatemala", locale: "es-GT" },
  { label: "America/Guayaquil", value: "America/Guayaquil", locale: "es-EC" },
  { label: "America/Guyana", value: "America/Guyana", locale: "en-GY" },
  { label: "America/Halifax", value: "America/Halifax", locale: "en-CA" },
  { label: "America/Havana", value: "America/Havana", locale: "es-CU" },
  { label: "America/Hermosillo", value: "America/Hermosillo", locale: "es-MX" },
  {
    label: "America/Indiana/Indianapolis",
    value: "America/Indiana/Indianapolis",
    locale: "en-US",
  },
  {
    label: "America/Indiana/Knox",
    value: "America/Indiana/Knox",
    locale: "en-US",
  },
  {
    label: "America/Indiana/Marengo",
    value: "America/Indiana/Marengo",
    locale: "en-US",
  },
  {
    label: "America/Indiana/Petersburg",
    value: "America/Indiana/Petersburg",
    locale: "en-US",
  },
  {
    label: "America/Indiana/Tell_City",
    value: "America/Indiana/Tell_City",
    locale: "en-US",
  },
  {
    label: "America/Indiana/Vevay",
    value: "America/Indiana/Vevay",
    locale: "en-US",
  },
  {
    label: "America/Indiana/Vincennes",
    value: "America/Indiana/Vincennes",
    locale: "en-US",
  },
  {
    label: "America/Indiana/Winamac",
    value: "America/Indiana/Winamac",
    locale: "en-US",
  },
  {
    label: "America/Indianapolis",
    value: "America/Indianapolis",
    locale: "en-US",
  },
  { label: "America/Inuvik", value: "America/Inuvik", locale: "en-CA" },
  { label: "America/Iqaluit", value: "America/Iqaluit", locale: "en-CA" },
  { label: "America/Jamaica", value: "America/Jamaica", locale: "en-JM" },
  { label: "America/Juneau", value: "America/Juneau", locale: "en-US" },
  {
    label: "America/Kentucky/Louisville",
    value: "America/Kentucky/Louisville",
    locale: "en-US",
  },
  {
    label: "America/Kentucky/Monticello",
    value: "America/Kentucky/Monticello",
    locale: "en-US",
  },
  { label: "America/La_Paz", value: "America/La_Paz", locale: "es-BO" },
  { label: "America/Lima", value: "America/Lima", locale: "es-PE" },
  {
    label: "America/Los_Angeles",
    value: "America/Los_Angeles",
    locale: "en-US",
  },
  { label: "America/Louisville", value: "America/Louisville", locale: "en-US" },
  {
    label: "America/Lower_Princes",
    value: "America/Lower_Princes",
    locale: "en-SX",
  },
  { label: "America/Maceio", value: "America/Maceio", locale: "pt-BR" },
  { label: "America/Managua", value: "America/Managua", locale: "es-NI" },
  { label: "America/Manaus", value: "America/Manaus", locale: "pt-BR" },
  { label: "America/Marigot", value: "America/Marigot", locale: "fr-MF" },
  { label: "America/Martinique", value: "America/Martinique", locale: "fr-MQ" },
  { label: "America/Matamoros", value: "America/Matamoros", locale: "es-MX" },
  { label: "America/Mazatlan", value: "America/Mazatlan", locale: "es-MX" },
  { label: "America/Mendoza", value: "America/Mendoza", locale: "es-AR" },
  {
    label: "America/Mexico_City",
    value: "America/Mexico_City",
    locale: "es-MX",
  },
  { label: "America/Miquelon", value: "America/Miquelon", locale: "fr-PM" },
  { label: "America/Moncton", value: "America/Moncton", locale: "en-CA" },
  { label: "America/Monterrey", value: "America/Monterrey", locale: "es-MX" },
  { label: "America/Montevideo", value: "America/Montevideo", locale: "es-UY" },
  { label: "America/Montreal", value: "America/Montreal", locale: "fr-CA" },
  { label: "America/Nassau", value: "America/Nassau", locale: "en-BS" },
  { label: "America/New_York", value: "America/New_York", locale: "en-US" },
  { label: "America/Nipigon", value: "America/Nipigon", locale: "en-CA" },
  { label: "America/Nome", value: "America/Nome", locale: "en-US" },
  { label: "America/Noronha", value: "America/Noronha", locale: "pt-BR" },
  {
    label: "America/North_Dakota/Beulah",
    value: "America/North_Dakota/Beulah",
    locale: "en-US",
  },
  {
    label: "America/North_Dakota/Center",
    value: "America/North_Dakota/Center",
    locale: "en-US",
  },
  {
    label: "America/North_Dakota/New_Salem",
    value: "America/North_Dakota/New_Salem",
    locale: "en-US",
  },
  { label: "America/Ojinaga", value: "America/Ojinaga", locale: "es-MX" },
  { label: "America/Panama", value: "America/Panama", locale: "es-PA" },
  {
    label: "America/Pangnirtung",
    value: "America/Pangnirtung",
    locale: "en-CA",
  },
  { label: "America/Paramaribo", value: "America/Paramaribo", locale: "nl-SR" },
  { label: "America/Phoenix", value: "America/Phoenix", locale: "en-US" },
  {
    label: "America/Port-au-Prince",
    value: "America/Port-au-Prince",
    locale: "ht-HT",
  },
  {
    label: "America/Port_of_Spain",
    value: "America/Port_of_Spain",
    locale: "en-TT",
  },
  {
    label: "America/Porto_Velho",
    value: "America/Porto_Velho",
    locale: "pt-BR",
  },
  {
    label: "America/Puerto_Rico",
    value: "America/Puerto_Rico",
    locale: "es-PR",
  },
  {
    label: "America/Rainy_River",
    value: "America/Rainy_River",
    locale: "en-CA",
  },
  {
    label: "America/Rankin_Inlet",
    value: "America/Rankin_Inlet",
    locale: "en-CA",
  },
  { label: "America/Recife", value: "America/Recife", locale: "pt-BR" },
  { label: "America/Regina", value: "America/Regina", locale: "en-CA" },
  { label: "America/Resolute", value: "America/Resolute", locale: "en-CA" },
  { label: "America/Rio_Branco", value: "America/Rio_Branco", locale: "pt-BR" },
  {
    label: "America/Rio_Gallegos",
    value: "America/Rio_Gallegos",
    locale: "es-AR",
  },
  { label: "America/Salta", value: "America/Salta", locale: "es-AR" },
  { label: "America/Santiago", value: "America/Santiago", locale: "es-CL" },
  { label: "America/Sao_Paulo", value: "America/Sao_Paulo", locale: "pt-BR" },
  {
    label: "America/Scoresbysund",
    value: "America/Scoresbysund",
    locale: "da-GL",
  },
  { label: "America/Sitka", value: "America/Sitka", locale: "en-US" },
  {
    label: "America/St_Barthelemy",
    value: "America/St_Barthelemy",
    locale: "fr-FR",
  },
  { label: "America/St_Johns", value: "America/St_Johns", locale: "en-CA" },
  { label: "America/St_Kitts", value: "America/St_Kitts", locale: "en-KN" },
  { label: "America/St_Lucia", value: "America/St_Lucia", locale: "en-LC" },
  { label: "America/St_Thomas", value: "America/St_Thomas", locale: "en-VI" },
  { label: "America/St_Vincent", value: "America/St_Vincent", locale: "en-VC" },
  {
    label: "America/Tegucigalpa",
    value: "America/Tegucigalpa",
    locale: "es-HN",
  },
  { label: "America/Thule", value: "America/Thule", locale: "da-GL" },
  {
    label: "America/Thunder_Bay",
    value: "America/Thunder_Bay",
    locale: "en-CA",
  },
  { label: "America/Tijuana", value: "America/Tijuana", locale: "es-MX" },
  { label: "America/Toronto", value: "America/Toronto", locale: "en-CA" },
  { label: "America/Tortola", value: "America/Tortola", locale: "en-VG" },
  { label: "America/Vancouver", value: "America/Vancouver", locale: "en-CA" },
  { label: "America/Whitehorse", value: "America/Whitehorse", locale: "en-CA" },
  { label: "America/Winnipeg", value: "America/Winnipeg", locale: "en-CA" },
  { label: "America/Yakutat", value: "America/Yakutat", locale: "en-US" },
  {
    label: "America/Yellowknife",
    value: "America/Yellowknife",
    locale: "en-CA",
  },
];

export const antarcticaTimeZone = [{
  label: "Antarctica/Casey",
  value: "Antarctica/Casey"
}, {
  label: "Antarctica/Davis",
  value: "Antarctica/Davis"
}, {
  label: "Antarctica/DumontDUrville",
  value: "Antarctica/DumontDUrville"
}, {
  label: "Antarctica/Macquarie",
  value: "Antarctica/Macquarie"
}, {
  label: "Antarctica/Mawson",
  value: "Antarctica/Mawson"
}, {
  label: "Antarctica/McMurdo",
  value: "Antarctica/McMurdo"
}, {
  label: "Antarctica/Palmer",
  value: "Antarctica/Palmer"
}, {
  label: "Antarctica/Rothera",
  value: "Antarctica/Rothera"
}, {
  label: "Antarctica/South_Pole",
  value: "Antarctica/South_Pole"
}, {
  label: "Antarctica/Syowa",
  value: "Antarctica/Syowa"
}, {
  label: "Antarctica/Troll",
  value: "Antarctica/Troll"
}, {
  label: "Antarctica/Vostok",
  value: "Antarctica/Vostok"
}];

export const antarcticaTimeZoneWithLocale = [
  { label: "Antarctica/Casey", value: "Antarctica/Casey", locale: "en-AU" },
  { label: "Antarctica/Davis", value: "Antarctica/Davis", locale: "en-AU" },
  {
    label: "Antarctica/DumontDUrville",
    value: "Antarctica/DumontDUrville",
    locale: "fr-FR",
  },
  {
    label: "Antarctica/Macquarie",
    value: "Antarctica/Macquarie",
    locale: "en-AU",
  },
  { label: "Antarctica/Mawson", value: "Antarctica/Mawson", locale: "en-AU" },
  { label: "Antarctica/McMurdo", value: "Antarctica/McMurdo", locale: "en-US" },
  { label: "Antarctica/Palmer", value: "Antarctica/Palmer", locale: "en-US" },
  { label: "Antarctica/Rothera", value: "Antarctica/Rothera", locale: "en-GB" },
  {
    label: "Antarctica/South_Pole",
    value: "Antarctica/South_Pole",
    locale: "en-US",
  },
  { label: "Antarctica/Syowa", value: "Antarctica/Syowa", locale: "ja-JP" },
  { label: "Antarctica/Troll", value: "Antarctica/Troll", locale: "no-NO" },
  { label: "Antarctica/Vostok", value: "Antarctica/Vostok", locale: "ru-RU" },
];

export const arcticTimeZone = [
  { value: "Arctic/Longyearbyen", label: "Arctic/Longyearbyen" },
];

export const arcticTimeZoneWithLocale = [
  {
    value: "Arctic/Longyearbyen",
    label: "Arctic/Longyearbyen",
    locale: "nb-NO",
  },
];

export const asiaTimeZone = [{
  label: "Asia/Aden",
  value: "Asia/Aden"
}, {
  label: "Asia/Almaty",
  value: "Asia/Almaty"
}, {
  label: "Asia/Amman",
  value: "Asia/Amman"
}, {
  label: "Asia/Anadyr",
  value: "Asia/Anadyr"
}, {
  label: "Asia/Aqtau",
  value: "Asia/Aqtau"
}, {
  label: "Asia/Aqtobe",
  value: "Asia/Aqtobe"
}, {
  label: "Asia/Ashgabat",
  value: "Asia/Ashgabat"
}, {
  label: "Asia/Ashkhabad",
  value: "Asia/Ashkhabad"
}, {
  label: "Asia/Atyrau",
  value: "Asia/Atyrau"
}, {
  label: "Asia/Baghdad",
  value: "Asia/Baghdad"
}, {
  label: "Asia/Bahrain",
  value: "Asia/Bahrain"
}, {
  label: "Asia/Baku",
  value: "Asia/Baku"
}, {
  label: "Asia/Bangkok",
  value: "Asia/Bangkok"
}, {
  label: "Asia/Barnaul",
  value: "Asia/Barnaul"
}, {
  label: "Asia/Beirut",
  value: "Asia/Beirut"
}, {
  label: "Asia/Bishkek",
  value: "Asia/Bishkek"
}, {
  label: "Asia/Brunei",
  value: "Asia/Brunei"
}, {
  label: "Asia/Calcutta",
  value: "Asia/Calcutta"
}, {
  label: "Asia/Chita",
  value: "Asia/Chita"
}, {
  label: "Asia/Choibalsan",
  value: "Asia/Choibalsan"
}, {
  label: "Asia/Chongqing",
  value: "Asia/Chongqing"
}, {
  label: "Asia/Chungking",
  value: "Asia/Chungking"
}, {
  label: "Asia/Colombo",
  value: "Asia/Colombo"
}, {
  label: "Asia/Dacca",
  value: "Asia/Dacca"
}, {
  label: "Asia/Damascus",
  value: "Asia/Damascus"
}, {
  label: "Asia/Dhaka",
  value: "Asia/Dhaka"
}, {
  label: "Asia/Dili",
  value: "Asia/Dili"
}, {
  label: "Asia/Dubai",
  value: "Asia/Dubai"
}, {
  label: "Asia/Dushanbe",
  value: "Asia/Dushanbe"
}, {
  label: "Asia/Famagusta",
  value: "Asia/Famagusta"
}, {
  label: "Asia/Gaza",
  value: "Asia/Gaza"
}, {
  label: "Asia/Harbin",
  value: "Asia/Harbin"
}, {
  label: "Asia/Hebron",
  value: "Asia/Hebron"
}, {
  label: "Asia/Ho_Chi_Minh",
  value: "Asia/Ho_Chi_Minh"
}, {
  label: "Asia/Hong_Kong",
  value: "Asia/Hong_Kong"
}, {
  label: "Asia/Hovd",
  value: "Asia/Hovd"
}, {
  label: "Asia/Irkutsk",
  value: "Asia/Irkutsk"
}, {
  label: "Asia/Istanbul",
  value: "Asia/Istanbul"
}, {
  label: "Asia/Jakarta",
  value: "Asia/Jakarta"
}, {
  label: "Asia/Jayapura",
  value: "Asia/Jayapura"
}, {
  label: "Asia/Jerusalem",
  value: "Asia/Jerusalem"
}, {
  label: "Asia/Kabul",
  value: "Asia/Kabul"
}, {
  label: "Asia/Kamchatka",
  value: "Asia/Kamchatka"
}, {
  label: "Asia/Karachi",
  value: "Asia/Karachi"
}, {
  label: "Asia/Kashgar",
  value: "Asia/Kashgar"
}, {
  label: "Asia/Kathmandu",
  value: "Asia/Kathmandu"
}, {
  label: "Asia/Katmandu",
  value: "Asia/Katmandu"
}, {
  label: "Asia/Khandyga",
  value: "Asia/Khandyga"
}, {
  label: "Asia/Kolkata",
  value: "Asia/Kolkata"
}, {
  label: "Asia/Krasnoyarsk",
  value: "Asia/Krasnoyarsk"
}, {
  label: "Asia/Kuala_Lumpur",
  value: "Asia/Kuala_Lumpur"
}, {
  label: "Asia/Kuching",
  value: "Asia/Kuching"
}, {
  label: "Asia/Kuwait",
  value: "Asia/Kuwait"
}, {
  label: "Asia/Macao",
  value: "Asia/Macao"
}, {
  label: "Asia/Macau",
  value: "Asia/Macau"
}, {
  label: "Asia/Magadan",
  value: "Asia/Magadan"
}, {
  label: "Asia/Makassar",
  value: "Asia/Makassar"
}, {
  label: "Asia/Manila",
  value: "Asia/Manila"
}, {
  label: "Asia/Muscat",
  value: "Asia/Muscat"
}, {
  label: "Asia/Nicosia",
  value: "Asia/Nicosia"
}, {
  label: "Asia/Novokuznetsk",
  value: "Asia/Novokuznetsk"
}, {
  label: "Asia/Novosibirsk",
  value: "Asia/Novosibirsk"
}, {
  label: "Asia/Omsk",
  value: "Asia/Omsk"
}, {
  label: "Asia/Oral",
  value: "Asia/Oral"
}, {
  label: "Asia/Phnom_Penh",
  value: "Asia/Phnom_Penh"
}, {
  label: "Asia/Pontianak",
  value: "Asia/Pontianak"
}, {
  label: "Asia/Pyongyang",
  value: "Asia/Pyongyang"
}, {
  label: "Asia/Qatar",
  value: "Asia/Qatar"
}, {
  label: "Asia/Qyzylorda",
  value: "Asia/Qyzylorda"
}, {
  label: "Asia/Rangoon",
  value: "Asia/Rangoon"
}, {
  label: "Asia/Riyadh",
  value: "Asia/Riyadh"
}, {
  label: "Asia/Saigon",
  value: "Asia/Saigon"
}, {
  label: "Asia/Sakhalin",
  value: "Asia/Sakhalin"
}, {
  label: "Asia/Samarkand",
  value: "Asia/Samarkand"
}, {
  label: "Asia/Seoul",
  value: "Asia/Seoul"
}, {
  label: "Asia/Shanghai",
  value: "Asia/Shanghai"
}, {
  label: "Asia/Singapore",
  value: "Asia/Singapore"
}, {
  label: "Asia/Srednekolymsk",
  value: "Asia/Srednekolymsk"
}, {
  label: "Asia/Taipei",
  value: "Asia/Taipei"
}, {
  label: "Asia/Tashkent",
  value: "Asia/Tashkent"
}, {
  label: "Asia/Tbilisi",
  value: "Asia/Tbilisi"
}, {
  label: "Asia/Tehran",
  value: "Asia/Tehran"
}, {
  label: "Asia/Tel_Aviv",
  value: "Asia/Tel_Aviv"
}, {
  label: "Asia/Thimbu",
  value: "Asia/Thimbu"
}, {
  label: "Asia/Thimphu",
  value: "Asia/Thimphu"
}, {
  label: "Asia/Tokyo",
  value: "Asia/Tokyo"
}, {
  label: "Asia/Tomsk",
  value: "Asia/Tomsk"
}, {
  label: "Asia/Ujung_Pandang",
  value: "Asia/Ujung_Pandang"
}, {
  label: "Asia/Ulaanbaatar",
  value: "Asia/Ulaanbaatar"
}, {
  label: "Asia/Ulan_Bator",
  value: "Asia/Ulan_Bator"
}, {
  label: "Asia/Urumqi",
  value: "Asia/Urumqi"
}, {
  label: "Asia/Ust-Nera",
  value: "Asia/Ust-Nera"
}, {
  label: "Asia/Vientiane",
  value: "Asia/Vientiane"
}, {
  label: "Asia/Vladivostok",
  value: "Asia/Vladivostok"
}, {
  label: "Asia/Yakutsk",
  value: "Asia/Yakutsk"
}, {
  label: "Asia/Yangon",
  value: "Asia/Yangon"
}, {
  label: "Asia/Yekaterinburg",
  value: "Asia/Yekaterinburg"
}, {
  label: "Asia/Yerevan",
  value: "Asia/Yerevan"
}];

export const asiaTimeZoneWithLocale = [
  { label: "Asia/Aden", value: "Asia/Aden", locale: "ar-YE" },
  { label: "Asia/Almaty", value: "Asia/Almaty", locale: "kk-KZ" },
  { label: "Asia/Amman", value: "Asia/Amman", locale: "ar-JO" },
  { label: "Asia/Anadyr", value: "Asia/Anadyr", locale: "ru-RU" },
  { label: "Asia/Aqtau", value: "Asia/Aqtau", locale: "kk-KZ" },
  { label: "Asia/Aqtobe", value: "Asia/Aqtobe", locale: "kk-KZ" },
  { label: "Asia/Ashgabat", value: "Asia/Ashgabat", locale: "tk-TM" },
  { label: "Asia/Ashkhabad", value: "Asia/Ashkhabad", locale: "tk-TM" },
  { label: "Asia/Atyrau", value: "Asia/Atyrau", locale: "kk-KZ" },
  { label: "Asia/Baghdad", value: "Asia/Baghdad", locale: "ar-IQ" },
  { label: "Asia/Bahrain", value: "Asia/Bahrain", locale: "ar-BH" },
  { label: "Asia/Baku", value: "Asia/Baku", locale: "az-AZ" },
  { label: "Asia/Bangkok", value: "Asia/Bangkok", locale: "th-TH" },
  { label: "Asia/Barnaul", value: "Asia/Barnaul", locale: "ru-RU" },
  { label: "Asia/Beirut", value: "Asia/Beirut", locale: "ar-LB" },
  { label: "Asia/Bishkek", value: "Asia/Bishkek", locale: "ky-KG" },
  { label: "Asia/Brunei", value: "Asia/Brunei", locale: "ms-BN" },
  { label: "Asia/Calcutta", value: "Asia/Calcutta", locale: "hi-IN" },
  { label: "Asia/Chita", value: "Asia/Chita", locale: "ru-RU" },
  { label: "Asia/Choibalsan", value: "Asia/Choibalsan", locale: "mn-MN" },
  { label: "Asia/Chongqing", value: "Asia/Chongqing", locale: "zh-CN" },
  { label: "Asia/Colombo", value: "Asia/Colombo", locale: "si-LK" },
  { label: "Asia/Damascus", value: "Asia/Damascus", locale: "ar-SY" },
  { label: "Asia/Dhaka", value: "Asia/Dhaka", locale: "bn-BD" },
  { label: "Asia/Dili", value: "Asia/Dili", locale: "pt-TL" },
  { label: "Asia/Dubai", value: "Asia/Dubai", locale: "ar-AE" },
  { label: "Asia/Dushanbe", value: "Asia/Dushanbe", locale: "tg-TJ" },
  { label: "Asia/Famagusta", value: "Asia/Famagusta", locale: "tr-CY" },
  { label: "Asia/Gaza", value: "Asia/Gaza", locale: "ar-PS" },
  { label: "Asia/Hebron", value: "Asia/Hebron", locale: "ar-PS" },
  { label: "Asia/Ho_Chi_Minh", value: "Asia/Ho_Chi_Minh", locale: "vi-VN" },
  { label: "Asia/Hong_Kong", value: "Asia/Hong_Kong", locale: "zh-HK" },
  { label: "Asia/Irkutsk", value: "Asia/Irkutsk", locale: "ru-RU" },
  { label: "Asia/Istanbul", value: "Asia/Istanbul", locale: "tr-TR" },
  { label: "Asia/Jakarta", value: "Asia/Jakarta", locale: "id-ID" },
  { label: "Asia/Jerusalem", value: "Asia/Jerusalem", locale: "he-IL" },
  { label: "Asia/Kabul", value: "Asia/Kabul", locale: "fa-AF" },
  { label: "Asia/Kamchatka", value: "Asia/Kamchatka", locale: "ru-RU" },
  { label: "Asia/Karachi", value: "Asia/Karachi", locale: "ur-PK" },
  { label: "Asia/Kathmandu", value: "Asia/Kathmandu", locale: "ne-NP" },
  { label: "Asia/Kolkata", value: "Asia/Kolkata", locale: "hi-IN" },
  { label: "Asia/Kuala_Lumpur", value: "Asia/Kuala_Lumpur", locale: "ms-MY" },
  { label: "Asia/Kuwait", value: "Asia/Kuwait", locale: "ar-KW" },
  { label: "Asia/Macau", value: "Asia/Macau", locale: "zh-MO" },
  { label: "Asia/Magadan", value: "Asia/Magadan", locale: "ru-RU" },
  { label: "Asia/Manila", value: "Asia/Manila", locale: "tl-PH" },
  { label: "Asia/Muscat", value: "Asia/Muscat", locale: "ar-OM" },
  { label: "Asia/Nicosia", value: "Asia/Nicosia", locale: "el-CY" },
  { label: "Asia/Qatar", value: "Asia/Qatar", locale: "ar-QA" },
  { label: "Asia/Riyadh", value: "Asia/Riyadh", locale: "ar-SA" },
  { label: "Asia/Seoul", value: "Asia/Seoul", locale: "ko-KR" },
  { label: "Asia/Shanghai", value: "Asia/Shanghai", locale: "zh-CN" },
  { label: "Asia/Singapore", value: "Asia/Singapore", locale: "en-SG" },
  { label: "Asia/Taipei", value: "Asia/Taipei", locale: "zh-TW" },
  { label: "Asia/Tashkent", value: "Asia/Tashkent", locale: "uz-UZ" },
  { label: "Asia/Tbilisi", value: "Asia/Tbilisi", locale: "ka-GE" },
  { label: "Asia/Tehran", value: "Asia/Tehran", locale: "fa-IR" },
  { label: "Asia/Thimphu", value: "Asia/Thimphu", locale: "dz-BT" },
  { label: "Asia/Tokyo", value: "Asia/Tokyo", locale: "ja-JP" },
  { label: "Asia/Ulaanbaatar", value: "Asia/Ulaanbaatar", locale: "mn-MN" },
  { label: "Asia/Urumqi", value: "Asia/Urumqi", locale: "zh-CN" },
  { label: "Asia/Vientiane", value: "Asia/Vientiane", locale: "lo-LA" },
  { label: "Asia/Vladivostok", value: "Asia/Vladivostok", locale: "ru-RU" },
  { label: "Asia/Yakutsk", value: "Asia/Yakutsk", locale: "ru-RU" },
  { label: "Asia/Yangon", value: "Asia/Yangon", locale: "my-MM" },
  { label: "Asia/Yekaterinburg", value: "Asia/Yekaterinburg", locale: "ru-RU" },
  { label: "Asia/Yerevan", value: "Asia/Yerevan", locale: "hy-AM" },
];

export const atlanticTimeZone = [{
  label: "Atlantic/Azores",
  value: "Atlantic/Azores"
}, {
  label: "Atlantic/Bermuda",
  value: "Atlantic/Bermuda"
}, {
  label: "Atlantic/Canary",
  value: "Atlantic/Canary"
}, {
  label: "Atlantic/Cape_Verde",
  value: "Atlantic/Cape_Verde"
}, {
  label: "Atlantic/Faeroe",
  value: "Atlantic/Faeroe"
}, {
  label: "Atlantic/Faroe",
  value: "Atlantic/Faroe"
}, {
  label: "Atlantic/Jan_Mayen",
  value: "Atlantic/Jan_Mayen"
}, {
  label: "Atlantic/Madeira",
  value: "Atlantic/Madeira"
}, {
  label: "Atlantic/Reykjavik",
  value: "Atlantic/Reykjavik"
}, {
  label: "Atlantic/South_Georgia",
  value: "Atlantic/South_Georgia"
}, {
  label: "Atlantic/St_Helena",
  value: "Atlantic/St_Helena"
}, {
  label: "Atlantic/Stanley",
  value: "Atlantic/Stanley"
}];

export const atlanticTimeZoneWithLocale = [
  { label: "Atlantic/Azores", value: "Atlantic/Azores", locale: "pt-PT" },
  { label: "Atlantic/Bermuda", value: "Atlantic/Bermuda", locale: "en-BM" },
  { label: "Atlantic/Canary", value: "Atlantic/Canary", locale: "es-ES" },
  {
    label: "Atlantic/Cape_Verde",
    value: "Atlantic/Cape_Verde",
    locale: "pt-CV",
  },
  { label: "Atlantic/Faeroe", value: "Atlantic/Faeroe", locale: "fo-FO" },
  { label: "Atlantic/Faroe", value: "Atlantic/Faroe", locale: "fo-FO" },
  { label: "Atlantic/Jan_Mayen", value: "Atlantic/Jan_Mayen", locale: "nb-NO" },
  { label: "Atlantic/Madeira", value: "Atlantic/Madeira", locale: "pt-PT" },
  { label: "Atlantic/Reykjavik", value: "Atlantic/Reykjavik", locale: "is-IS" },
  {
    label: "Atlantic/South_Georgia",
    value: "Atlantic/South_Georgia",
    locale: "en-GS",
  },
  { label: "Atlantic/St_Helena", value: "Atlantic/St_Helena", locale: "en-SH" },
  { label: "Atlantic/Stanley", value: "Atlantic/Stanley", locale: "en-FK" },
];

export const australiaTimeZone = [{
  label: "Australia/ACT",
  value: "Australia/ACT"
}, {
  label: "Australia/Adelaide",
  value: "Australia/Adelaide"
}, {
  label: "Australia/Brisbane",
  value: "Australia/Brisbane"
}, {
  label: "Australia/Broken_Hill",
  value: "Australia/Broken_Hill"
}, {
  label: "Australia/Canberra",
  value: "Australia/Canberra"
}, {
  label: "Australia/Currie",
  value: "Australia/Currie"
}, {
  label: "Australia/Darwin",
  value: "Australia/Darwin"
}, {
  label: "Australia/Eucla",
  value: "Australia/Eucla"
}, {
  label: "Australia/Hobart",
  value: "Australia/Hobart"
}, {
  label: "Australia/LHI",
  value: "Australia/LHI"
}, {
  label: "Australia/Lindeman",
  value: "Australia/Lindeman"
}, {
  label: "Australia/Lord_Howe",
  value: "Australia/Lord_Howe"
}, {
  label: "Australia/Melbourne",
  value: "Australia/Melbourne"
}, {
  label: "Australia/NSW",
  value: "Australia/NSW"
}, {
  label: "Australia/North",
  value: "Australia/North"
}, {
  label: "Australia/Perth",
  value: "Australia/Perth"
}, {
  label: "Australia/Queensland",
  value: "Australia/Queensland"
}, {
  label: "Australia/South",
  value: "Australia/South"
}, {
  label: "Australia/Sydney",
  value: "Australia/Sydney"
}, {
  label: "Australia/Tasmania",
  value: "Australia/Tasmania"
}, {
  label: "Australia/Victoria",
  value: "Australia/Victoria"
}, {
  label: "Australia/West",
  value: "Australia/West"
}, {
  label: "Australia/Yancowinna",
  value: "Australia/Yancowinna"
}];

export const australiaTimeZoneWithLocale = [
  { label: "Australia/ACT", value: "Australia/ACT", locale: "en-AU" },
  { label: "Australia/Adelaide", value: "Australia/Adelaide", locale: "en-AU" },
  { label: "Australia/Brisbane", value: "Australia/Brisbane", locale: "en-AU" },
  {
    label: "Australia/Broken_Hill",
    value: "Australia/Broken_Hill",
    locale: "en-AU",
  },
  { label: "Australia/Canberra", value: "Australia/Canberra", locale: "en-AU" },
  { label: "Australia/Currie", value: "Australia/Currie", locale: "en-AU" },
  { label: "Australia/Darwin", value: "Australia/Darwin", locale: "en-AU" },
  { label: "Australia/Eucla", value: "Australia/Eucla", locale: "en-AU" },
  { label: "Australia/Hobart", value: "Australia/Hobart", locale: "en-AU" },
  { label: "Australia/LHI", value: "Australia/LHI", locale: "en-AU" },
  { label: "Australia/Lindeman", value: "Australia/Lindeman", locale: "en-AU" },
  {
    label: "Australia/Lord_Howe",
    value: "Australia/Lord_Howe",
    locale: "en-AU",
  },
  {
    label: "Australia/Melbourne",
    value: "Australia/Melbourne",
    locale: "en-AU",
  },
  { label: "Australia/NSW", value: "Australia/NSW", locale: "en-AU" },
  { label: "Australia/North", value: "Australia/North", locale: "en-AU" },
  { label: "Australia/Perth", value: "Australia/Perth", locale: "en-AU" },
  {
    label: "Australia/Queensland",
    value: "Australia/Queensland",
    locale: "en-AU",
  },
  { label: "Australia/South", value: "Australia/South", locale: "en-AU" },
  { label: "Australia/Sydney", value: "Australia/Sydney", locale: "en-AU" },
  { label: "Australia/Tasmania", value: "Australia/Tasmania", locale: "en-AU" },
  { label: "Australia/Victoria", value: "Australia/Victoria", locale: "en-AU" },
  { label: "Australia/West", value: "Australia/West", locale: "en-AU" },
  {
    label: "Australia/Yancowinna",
    value: "Australia/Yancowinna",
    locale: "en-AU",
  },
];

export const brazilTimeZone = [{
  label: "Brazil/Acre",
  value: "Brazil/Acre"
}, {
  label: "Brazil/DeNoronha",
  value: "Brazil/DeNoronha"
}, {
  label: "Brazil/East",
  value: "Brazil/East"
}, {
  label: "Brazil/West",
  value: "Brazil/West"
}];

export const brazilTimeZoneWithLocale = [
  { label: "Brazil/Acre", value: "Brazil/Acre", locale: "pt-BR" }, // Portuguese (Brazil)
  { label: "Brazil/DeNoronha", value: "Brazil/DeNoronha", locale: "pt-BR" }, // Fernando de Noronha, Brazil
  { label: "Brazil/East", value: "Brazil/East", locale: "pt-BR" }, // Eastern Brazil (Brasília Time)
  { label: "Brazil/West", value: "Brazil/West", locale: "pt-BR" }, // Western Brazil (Amazon Time)
];

export const chileTimeZone = [{
  label: "Chile/Continental",
  value: "Chile/Continental"
}, {
  label: "Chile/EasterIsland",
  value: "Chile/EasterIsland"
}];

export const chileTimeZoneWithLocale = [
  { label: "Chile/Continental", value: "Chile/Continental", locale: "es-CL" }, // Spanish (Chile)
  { label: "Chile/EasterIsland", value: "Chile/EasterIsland", locale: "es-CL" }, // Spanish (Easter Island, Chile)
];

export const indianTimeZone = [{
  label: "Indian/Antananarivo",
  value: "Indian/Antananarivo"
}, {
  label: "Indian/Chagos",
  value: "Indian/Chagos"
}, {
  label: "Indian/Christmas",
  value: "Indian/Christmas"
}, {
  label: "Indian/Cocos",
  value: "Indian/Cocos"
}, {
  label: "Indian/Comoro",
  value: "Indian/Comoro"
}, {
  label: "Indian/Kerguelen",
  value: "Indian/Kerguelen"
}, {
  label: "Indian/Mahe",
  value: "Indian/Mahe"
}, {
  label: "Indian/Maldives",
  value: "Indian/Maldives"
}, {
  label: "Indian/Mauritius",
  value: "Indian/Mauritius"
}, {
  label: "Indian/Mayotte",
  value: "Indian/Mayotte"
}, {
  label: "Indian/Reunion",
  value: "Indian/Reunion"
}];

export const indianTimeZoneWithLocale = [
  {
    label: "Indian/Antananarivo",
    value: "Indian/Antananarivo",
    locale: "mg-MG",
  }, // Malagasy (Madagascar)
  { label: "Indian/Chagos", value: "Indian/Chagos", locale: "en-IO" }, // English (British Indian Ocean Territory)
  { label: "Indian/Christmas", value: "Indian/Christmas", locale: "en-CX" }, // English (Christmas Island, Australia)
  { label: "Indian/Cocos", value: "Indian/Cocos", locale: "ms-CC" }, // Malay (Cocos (Keeling) Islands, Australia)
  { label: "Indian/Comoro", value: "Indian/Comoro", locale: "ar-KM" }, // Arabic (Comoros)
  { label: "Indian/Kerguelen", value: "Indian/Kerguelen", locale: "fr-TF" }, // French (French Southern and Antarctic Lands)
  { label: "Indian/Mahe", value: "Indian/Mahe", locale: "fr-SC" }, // French (Seychelles)
  { label: "Indian/Maldives", value: "Indian/Maldives", locale: "dv-MV" }, // Dhivehi (Maldives)
  { label: "Indian/Mauritius", value: "Indian/Mauritius", locale: "en-MU" }, // English (Mauritius)
  { label: "Indian/Mayotte", value: "Indian/Mayotte", locale: "fr-YT" }, // French (Mayotte)
  { label: "Indian/Reunion", value: "Indian/Reunion", locale: "fr-RE" }, // French (Réunion)
];

export const mexicoTimeZone = [{
  label: "Mexico/BajaNorte",
  value: "Mexico/BajaNorte"
}, {
  label: "Mexico/BajaSur",
  value: "Mexico/BajaSur"
}, {
  label: "Mexico/General",
  value: "Mexico/General"
}];

export const mexicoTimeZoneWithLocale = [
  { label: "Mexico/BajaNorte", value: "Mexico/BajaNorte", locale: "es-MX" },
  { label: "Mexico/BajaSur", value: "Mexico/BajaSur", locale: "es-MX" },
  { label: "Mexico/General", value: "Mexico/General", locale: "es-MX" },
];

export const pacificTimeZone = [{
  label: "Pacific/Apia",
  value: "Pacific/Apia"
}, {
  label: "Pacific/Auckland",
  value: "Pacific/Auckland"
}, {
  label: "Pacific/Bougainville",
  value: "Pacific/Bougainville"
}, {
  label: "Pacific/Chatham",
  value: "Pacific/Chatham"
}, {
  label: "Pacific/Chuuk",
  value: "Pacific/Chuuk"
}, {
  label: "Pacific/Easter",
  value: "Pacific/Easter"
}, {
  label: "Pacific/Efate",
  value: "Pacific/Efate"
}, {
  label: "Pacific/Enderbury",
  value: "Pacific/Enderbury"
}, {
  label: "Pacific/Fakaofo",
  value: "Pacific/Fakaofo"
}, {
  label: "Pacific/Fiji",
  value: "Pacific/Fiji"
}, {
  label: "Pacific/Funafuti",
  value: "Pacific/Funafuti"
}, {
  label: "Pacific/Galapagos",
  value: "Pacific/Galapagos"
}, {
  label: "Pacific/Gambier",
  value: "Pacific/Gambier"
}, {
  label: "Pacific/Guadalcanal",
  value: "Pacific/Guadalcanal"
}, {
  label: "Pacific/Guam",
  value: "Pacific/Guam"
}, {
  label: "Pacific/Honolulu",
  value: "Pacific/Honolulu"
}, {
  label: "Pacific/Johnston",
  value: "Pacific/Johnston"
}, {
  label: "Pacific/Kiritimati",
  value: "Pacific/Kiritimati"
}, {
  label: "Pacific/Kosrae",
  value: "Pacific/Kosrae"
}, {
  label: "Pacific/Kwajalein",
  value: "Pacific/Kwajalein"
}, {
  label: "Pacific/Majuro",
  value: "Pacific/Majuro"
}, {
  label: "Pacific/Marquesas",
  value: "Pacific/Marquesas"
}, {
  label: "Pacific/Midway",
  value: "Pacific/Midway"
}, {
  label: "Pacific/Nauru",
  value: "Pacific/Nauru"
}, {
  label: "Pacific/Niue",
  value: "Pacific/Niue"
}, {
  label: "Pacific/Norfolk",
  value: "Pacific/Norfolk"
}, {
  label: "Pacific/Noumea",
  value: "Pacific/Noumea"
}, {
  label: "Pacific/Pago_Pago",
  value: "Pacific/Pago_Pago"
}, {
  label: "Pacific/Palau",
  value: "Pacific/Palau"
}, {
  label: "Pacific/Pitcairn",
  value: "Pacific/Pitcairn"
}, {
  label: "Pacific/Pohnpei",
  value: "Pacific/Pohnpei"
}, {
  label: "Pacific/Ponape",
  value: "Pacific/Ponape"
}, {
  label: "Pacific/Port_Moresby",
  value: "Pacific/Port_Moresby"
}, {
  label: "Pacific/Rarotonga",
  value: "Pacific/Rarotonga"
}, {
  label: "Pacific/Saipan",
  value: "Pacific/Saipan"
}, {
  label: "Pacific/Samoa",
  value: "Pacific/Samoa"
}, {
  label: "Pacific/Tahiti",
  value: "Pacific/Tahiti"
}, {
  label: "Pacific/Tarawa",
  value: "Pacific/Tarawa"
}, {
  label: "Pacific/Tongatapu",
  value: "Pacific/Tongatapu"
}, {
  label: "Pacific/Truk",
  value: "Pacific/Truk"
}, {
  label: "Pacific/Wake",
  value: "Pacific/Wake"
}, {
  label: "Pacific/Wallis",
  value: "Pacific/Wallis"
}, {
  label: "Pacific/Yap",
  value: "Pacific/Yap"
}];

export const pacificTimeZoneWithLocale = [
  { label: "Pacific/Apia", value: "Pacific/Apia", locale: "sm-WS" },
  { label: "Pacific/Auckland", value: "Pacific/Auckland", locale: "en-NZ" },
  {
    label: "Pacific/Bougainville",
    value: "Pacific/Bougainville",
    locale: "en-PG",
  },
  { label: "Pacific/Chatham", value: "Pacific/Chatham", locale: "en-NZ" },
  { label: "Pacific/Chuuk", value: "Pacific/Chuuk", locale: "en-FM" },
  { label: "Pacific/Easter", value: "Pacific/Easter", locale: "es-CL" },
  { label: "Pacific/Efate", value: "Pacific/Efate", locale: "bi-VU" },
  { label: "Pacific/Enderbury", value: "Pacific/Enderbury", locale: "en-KI" },
  { label: "Pacific/Fakaofo", value: "Pacific/Fakaofo", locale: "en-TK" },
  { label: "Pacific/Fiji", value: "Pacific/Fiji", locale: "en-FJ" },
  { label: "Pacific/Funafuti", value: "Pacific/Funafuti", locale: "en-TV" },
  { label: "Pacific/Galapagos", value: "Pacific/Galapagos", locale: "es-EC" },
  { label: "Pacific/Gambier", value: "Pacific/Gambier", locale: "fr-PF" },
  {
    label: "Pacific/Guadalcanal",
    value: "Pacific/Guadalcanal",
    locale: "en-SB",
  },
  { label: "Pacific/Guam", value: "Pacific/Guam", locale: "en-GU" },
  { label: "Pacific/Honolulu", value: "Pacific/Honolulu", locale: "en-US" },
  { label: "Pacific/Johnston", value: "Pacific/Johnston", locale: "en-US" },
  { label: "Pacific/Kiritimati", value: "Pacific/Kiritimati", locale: "en-KI" },
  { label: "Pacific/Kosrae", value: "Pacific/Kosrae", locale: "en-FM" },
  { label: "Pacific/Kwajalein", value: "Pacific/Kwajalein", locale: "en-MH" },
  { label: "Pacific/Majuro", value: "Pacific/Majuro", locale: "en-MH" },
  { label: "Pacific/Marquesas", value: "Pacific/Marquesas", locale: "fr-PF" },
  { label: "Pacific/Midway", value: "Pacific/Midway", locale: "en-UM" },
  { label: "Pacific/Nauru", value: "Pacific/Nauru", locale: "en-NR" },
  { label: "Pacific/Niue", value: "Pacific/Niue", locale: "en-NU" },
  { label: "Pacific/Norfolk", value: "Pacific/Norfolk", locale: "en-NF" },
  { label: "Pacific/Noumea", value: "Pacific/Noumea", locale: "fr-NC" },
  { label: "Pacific/Pago_Pago", value: "Pacific/Pago_Pago", locale: "sm-AS" },
  { label: "Pacific/Palau", value: "Pacific/Palau", locale: "en-PW" },
  { label: "Pacific/Pitcairn", value: "Pacific/Pitcairn", locale: "en-PN" },
  { label: "Pacific/Pohnpei", value: "Pacific/Pohnpei", locale: "en-FM" },
  { label: "Pacific/Ponape", value: "Pacific/Ponape", locale: "en-FM" },
  {
    label: "Pacific/Port_Moresby",
    value: "Pacific/Port_Moresby",
    locale: "en-PG",
  },
  { label: "Pacific/Rarotonga", value: "Pacific/Rarotonga", locale: "en-CK" },
  { label: "Pacific/Saipan", value: "Pacific/Saipan", locale: "en-MP" },
  { label: "Pacific/Samoa", value: "Pacific/Samoa", locale: "sm-WS" },
  { label: "Pacific/Tahiti", value: "Pacific/Tahiti", locale: "fr-PF" },
  { label: "Pacific/Tarawa", value: "Pacific/Tarawa", locale: "en-KI" },
  { label: "Pacific/Tongatapu", value: "Pacific/Tongatapu", locale: "to-TO" },
  { label: "Pacific/Truk", value: "Pacific/Truk", locale: "en-FM" },
  { label: "Pacific/Wake", value: "Pacific/Wake", locale: "en-UM" },
  { label: "Pacific/Wallis", value: "Pacific/Wallis", locale: "fr-WF" },
  { label: "Pacific/Yap", value: "Pacific/Yap", locale: "en-FM" },
];

export const etcTimeZone = [{
  label: "Etc/GMT",
  value: "Etc/GMT"
}, {
  label: "Etc/GMT+0",
  value: "Etc/GMT+0"
}, {
  label: "Etc/GMT+1",
  value: "Etc/GMT+1"
}, {
  label: "Etc/GMT+10",
  value: "Etc/GMT+10"
}, {
  label: "Etc/GMT+11",
  value: "Etc/GMT+11"
}, {
  label: "Etc/GMT+12",
  value: "Etc/GMT+12"
}, {
  label: "Etc/GMT+2",
  value: "Etc/GMT+2"
}, {
  label: "Etc/GMT+3",
  value: "Etc/GMT+3"
}, {
  label: "Etc/GMT+4",
  value: "Etc/GMT+4"
}, {
  label: "Etc/GMT+5",
  value: "Etc/GMT+5"
}, {
  label: "Etc/GMT+6",
  value: "Etc/GMT+6"
}, {
  label: "Etc/GMT+7",
  value: "Etc/GMT+7"
}, {
  label: "Etc/GMT+8",
  value: "Etc/GMT+8"
}, {
  label: "Etc/GMT+9",
  value: "Etc/GMT+9"
}, {
  label: "Etc/GMT-0",
  value: "Etc/GMT-0"
}, {
  label: "Etc/GMT-1",
  value: "Etc/GMT-1"
}, {
  label: "Etc/GMT-10",
  value: "Etc/GMT-10"
}, {
  label: "Etc/GMT-11",
  value: "Etc/GMT-11"
}, {
  label: "Etc/GMT-12",
  value: "Etc/GMT-12"
}, {
  label: "Etc/GMT-13",
  value: "Etc/GMT-13"
}, {
  label: "Etc/GMT-14",
  value: "Etc/GMT-14"
}, {
  label: "Etc/GMT-2",
  value: "Etc/GMT-2"
}, {
  label: "Etc/GMT-3",
  value: "Etc/GMT-3"
}, {
  label: "Etc/GMT-4",
  value: "Etc/GMT-4"
}, {
  label: "Etc/GMT-5",
  value: "Etc/GMT-5"
}, {
  label: "Etc/GMT-6",
  value: "Etc/GMT-6"
}, {
  label: "Etc/GMT-7",
  value: "Etc/GMT-7"
}, {
  label: "Etc/GMT-8",
  value: "Etc/GMT-8"
}, {
  label: "Etc/GMT-9",
  value: "Etc/GMT-9"
}, {
  label: "Etc/GMT0",
  value: "Etc/GMT0"
}, {
  label: "Etc/Greenwich",
  value: "Etc/Greenwich"
}, {
  label: "Etc/UCT",
  value: "Etc/UCT"
}, {
  label: "Etc/UTC",
  value: "Etc/UTC"
}, {
  label: "Etc/Universal",
  value: "Etc/Universal"
}, {
  label: "Etc/Zulu",
  value: "Etc/Zulu"
}]

export const etcTimeZoneWithLocale = [
  { label: "Etc/GMT", value: "Etc/GMT", locale: "en-GB" },
  { label: "Etc/GMT+0", value: "Etc/GMT+0", locale: "en-GB" },
  { label: "Etc/GMT+1", value: "Etc/GMT+1", locale: "en-GB" },
  { label: "Etc/GMT+10", value: "Etc/GMT+10", locale: "en-AU" },
  { label: "Etc/GMT+11", value: "Etc/GMT+11", locale: "en-AU" },
  { label: "Etc/GMT+12", value: "Etc/GMT+12", locale: "en-NZ" },
  { label: "Etc/GMT+2", value: "Etc/GMT+2", locale: "fr-FR" },
  { label: "Etc/GMT+3", value: "Etc/GMT+3", locale: "ru-RU" },
  { label: "Etc/GMT+4", value: "Etc/GMT+4", locale: "ru-RU" },
  { label: "Etc/GMT+5", value: "Etc/GMT+5", locale: "en-IN" },
  { label: "Etc/GMT+6", value: "Etc/GMT+6", locale: "en-BD" },
  { label: "Etc/GMT+7", value: "Etc/GMT+7", locale: "en-TH" },
  { label: "Etc/GMT+8", value: "Etc/GMT+8", locale: "zh-CN" },
  { label: "Etc/GMT+9", value: "Etc/GMT+9", locale: "ja-JP" },
  { label: "Etc/GMT-0", value: "Etc/GMT-0", locale: "en-GB" },
  { label: "Etc/GMT-1", value: "Etc/GMT-1", locale: "en-PT" },
  { label: "Etc/GMT-10", value: "Etc/GMT-10", locale: "en-US" },
  { label: "Etc/GMT-11", value: "Etc/GMT-11", locale: "en-US" },
  { label: "Etc/GMT-12", value: "Etc/GMT-12", locale: "en-US" },
  { label: "Etc/GMT-13", value: "Etc/GMT-13", locale: "en-TK" },
  { label: "Etc/GMT-14", value: "Etc/GMT-14", locale: "en-KI" },
  { label: "Etc/GMT-2", value: "Etc/GMT-2", locale: "fr-FR" },
  { label: "Etc/GMT-3", value: "Etc/GMT-3", locale: "ru-RU" },
  { label: "Etc/GMT-4", value: "Etc/GMT-4", locale: "ar-AE" },
  { label: "Etc/GMT-5", value: "Etc/GMT-5", locale: "en-PK" },
  { label: "Etc/GMT-6", value: "Etc/GMT-6", locale: "en-BD" },
  { label: "Etc/GMT-7", value: "Etc/GMT-7", locale: "en-KH" },
  { label: "Etc/GMT-8", value: "Etc/GMT-8", locale: "zh-CN" },
  { label: "Etc/GMT-9", value: "Etc/GMT-9", locale: "ja-JP" },
  { label: "Etc/GMT0", value: "Etc/GMT0", locale: "en-GB" },
  { label: "Etc/Greenwich", value: "Etc/Greenwich", locale: "en-GB" },
  { label: "Etc/UCT", value: "Etc/UCT", locale: "en-GB" },
  { label: "Etc/UTC", value: "Etc/UTC", locale: "en-GB" },
  { label: "Etc/Universal", value: "Etc/Universal", locale: "en-GB" },
  { label: "Etc/Zulu", value: "Etc/Zulu", locale: "en-GB" },
];

export const otherTimeZone = [{
  label: "CET",
  value: "CET"
}, {
  label: "CST6CDT",
  value: "CST6CDT"
}, {
  label: "Cuba",
  value: "Cuba"
}, {
  label: "EET",
  value: "EET"
}, {
  label: "EST",
  value: "EST"
}, {
  label: "EST5EDT",
  value: "EST5EDT"
}, {
  label: "Egypt",
  value: "Egypt"
}, {
  label: "Eire",
  value: "Eire"
}, {
  label: "GB",
  value: "GB"
}, {
  label: "GB-Eire",
  value: "GB-Eire"
}, {
  label: "GMT",
  value: "GMT"
}, {
  label: "GMT+0",
  value: "GMT+0"
}, {
  label: "GMT-0",
  value: "GMT-0"
}, {
  label: "GMT0",
  value: "GMT0"
}, {
  label: "Greenwich",
  value: "Greenwich"
}, {
  label: "HST",
  value: "HST"
}, {
  label: "Hongkong",
  value: "Hongkong"
}, {
  label: "Iceland",
  value: "Iceland"
}, {
  label: "Iran",
  value: "Iran"
}, {
  label: "Israel",
  value: "Israel"
}, {
  label: "Jamaica",
  value: "Jamaica"
}, {
  label: "Japan",
  value: "Japan"
}, {
  label: "Kwajalein",
  value: "Kwajalein"
}, {
  label: "Libya",
  value: "Libya"
}, {
  label: "MET",
  value: "MET"
}, {
  label: "MST",
  value: "MST"
}, {
  label: "MST7MDT",
  value: "MST7MDT"
}, {
  label: "NZ",
  value: "NZ"
}, {
  label: "NZ-CHAT",
  value: "NZ-CHAT"
}, {
  label: "Navajo",
  value: "Navajo"
}, {
  label: "PRC",
  value: "PRC"
}, {
  label: "PST8PDT",
  value: "PST8PDT"
}, {
  label: "Poland",
  value: "Poland"
}, {
  label: "Portugal",
  value: "Portugal"
}, {
  label: "ROC",
  value: "ROC"
}, {
  label: "ROK",
  value: "ROK"
}, {
  label: "Singapore",
  value: "Singapore"
}, {
  label: "Turkey",
  value: "Turkey"
}, {
  label: "UCT",
  value: "UCT"
}, {
  label: "UTC",
  value: "UTC"
}, {
  label: "Universal",
  value: "Universal"
}, {
  label: "W-SU",
  value: "W-SU"
}, {
  label: "WET",
  value: "WET"
}, {
  label: "Zulu",
  value: "Zulu"
}]

export const otherTimeZoneWithLocale = [
  { label: "CET", value: "CET", locale: "en-US" },
  { label: "CST6CDT", value: "CST6CDT", locale: "en-US" },
  { label: "Cuba", value: "Cuba", locale: "es-CU" },
  { label: "EET", value: "EET", locale: "en-US" },
  { label: "EST", value: "EST", locale: "en-US" },
  { label: "EST5EDT", value: "EST5EDT", locale: "en-US" },
  { label: "Egypt", value: "Egypt", locale: "ar-EG" },
  { label: "Eire", value: "Eire", locale: "en-IE" },
  { label: "GB", value: "GB", locale: "en-GB" },
  { label: "GB-Eire", value: "GB-Eire", locale: "en-GB" },
  { label: "GMT", value: "GMT", locale: "en-GB" },
  { label: "GMT+0", value: "GMT+0", locale: "en-GB" },
  { label: "GMT-0", value: "GMT-0", locale: "en-GB" },
  { label: "GMT0", value: "GMT0", locale: "en-GB" },
  { label: "Greenwich", value: "Greenwich", locale: "en-GB" },
  { label: "HST", value: "HST", locale: "en-US" },
  { label: "Hongkong", value: "Hongkong", locale: "zh-HK" },
  { label: "Iceland", value: "Iceland", locale: "is-IS" },
  { label: "Iran", value: "Iran", locale: "fa-IR" },
  { label: "Israel", value: "Israel", locale: "he-IL" },
  { label: "Jamaica", value: "Jamaica", locale: "en-JM" },
  { label: "Japan", value: "Japan", locale: "ja-JP" },
  { label: "Kwajalein", value: "Kwajalein", locale: "en-KW" },
  { label: "Libya", value: "Libya", locale: "ar-LY" },
  { label: "MET", value: "MET", locale: "en-US" },
  { label: "MST", value: "MST", locale: "en-US" },
  { label: "MST7MDT", value: "MST7MDT", locale: "en-US" },
  { label: "NZ", value: "NZ", locale: "en-NZ" },
  { label: "NZ-CHAT", value: "NZ-CHAT", locale: "en-NZ" },
  { label: "Navajo", value: "Navajo", locale: "en-US" },
  { label: "PRC", value: "PRC", locale: "zh-CN" },
  { label: "PST8PDT", value: "PST8PDT", locale: "en-US" },
  { label: "Poland", value: "Poland", locale: "pl-PL" },
  { label: "Portugal", value: "Portugal", locale: "pt-PT" },
  { label: "ROC", value: "ROC", locale: "zh-TW" },
  { label: "ROK", value: "ROK", locale: "ko-KR" },
  { label: "Singapore", value: "Singapore", locale: "en-SG" },
  { label: "Turkey", value: "Turkey", locale: "tr-TR" },
  { label: "UCT", value: "UCT", locale: "en-GB" },
  { label: "UTC", value: "UTC", locale: "en-GB" },
  { label: "Universal", value: "Universal", locale: "en-US" },
  { label: "W-SU", value: "W-SU", locale: "ru-RU" },
  { label: "WET", value: "WET", locale: "pt-PT" },
  { label: "Zulu", value: "Zulu", locale: "en-GB" },
];


export const timezoneOptionList = [
  {
    label: "US",
    options: usTimeZoneWithLocale,
  },
  {
    label: "Europe",
    options: europeTimeZoneWithLocale,
  },
  {
    label: "Canada",
    options: canadaTimeZoneWithLocale,
  },
  {
    label: "Africa",
    options: africaTimeZoneWithLocale,
  },
  {
    label: "America",
    options: americaTimeZoneWithLocale,
  },
  {
    label: "Antarctica",
    options: antarcticaTimeZoneWithLocale,
  },
  {
    label: "Arctic",
    options: arcticTimeZoneWithLocale
  },
  {
    label: "Asia",
    options: asiaTimeZoneWithLocale,
  },
  {
    label: "Atlantic",
    options: atlanticTimeZoneWithLocale,
  },
  {
    label: "Australia",
    options: australiaTimeZoneWithLocale,
  },
  {
    label: "Brazil",
    options: brazilTimeZoneWithLocale,
  },
  {
    label: "Chile",
    options: chileTimeZoneWithLocale,
  },
  {
    label: "ETC",
    options: etcTimeZoneWithLocale,
  },
  {
    label: "Indian",
    options: indianTimeZoneWithLocale,
  },
  {
    label: "Mexico",
    options: mexicoTimeZoneWithLocale,
  },
  {
    label: "Pacific",
    options: pacificTimeZoneWithLocale,
  },
  {
    label: "Other",
    options: otherTimeZoneWithLocale,
  },
];

export const sundayWeekStart = [
  "US",
  "Canada",
  "Mexico",
  "America",
  "Pacific",
  "Atlantic",
  "Australia",
  "Brazil",
  "Chile",
  "Antarctica",

  "CST6CDT",
  "EST5EDT",
  "HST",
  "EST",
  "MST7MDT",
  "PST8PDT",
  "Jamaica",
  "Navajo",
];

export const saturdayWeekStart = [

]
